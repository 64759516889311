export default {
    state: () => ({
        claimHistory: null,
        claimInfo: null,
    }),
    mutations: {
        SET_CLAIM_HISTORY(state, value) {
            state.claimHistory = value;
        },
        SET_CLAIM_INFO(state, value) {
            state.claimInfo = value;
        },
    },
    actions: {
        CLAIM_HISTORY({commit}, item) {
            commit('SET_CLAIM_HISTORY', item)
        },
        CLAIM_INFO({commit}, item) {
            commit('SET_CLAIM_INFO', item)
        },
    },
    getters: {
        GET_CLAIM_HISTORY: state => {
            return state.claimHistory
        },
        GET_CLAIM_INFO: state => {
            return state.claimInfo
        },
    }
}