<template>
	<main-color class="info">
		<div class="info__group">
			<img src="@/assets/claim/claim.png" alt="claim icon" class="info__image">
			<div class="info__group-text">
				<h1 class="info__heading">{{ $t('dexSidebar.claim') }}</h1>
				<p class="info__description">{{ $t('claimCenter.description') }}</p>
			</div>
		</div>
		<ul class="info__stats" v-if="isWalletConnected">
			<li class="info__item active-item">
				<p class="info__name">{{ $t('claimCenter.titles[0]') }}</p>
				<p class="info__value">{{ getAllRewards }}</p>
			</li>
			<li class="info__item">
				<p class="info__name">{{ $t('claimCenter.titles[1]') }}</p>
				<p class="info__value">{{ getCashback }}</p>
			</li>
			<li class="info__item">
				<p class="info__name">{{ $t('claimCenter.titles[2]') }}</p>
				<p class="info__value">{{ getReferral }}</p>
			</li>
			<li class="info__item">
				<p class="info__name">{{ $t('claimCenter.titles[3]') }}</p>
				<p class="info__value">{{ getContests }}</p>
			</li>
		</ul>
		<button class="dex__button desktop-btn"
				v-else
				@click="connectWallet"
		>
			<div class="wallet-icon"></div>
			<p class="btn-text"> {{ $t("dexButton.connectWallet") }} </p>
		</button>
	</main-color>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
	name: "ClaimInfo",
	props: {
		tonConnectUi: {
			type: Object,
			default() {
				return {}
			}
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapGetters([
			'GET_DEX_WALLET',
			'GET_CLAIM_INFO',
		]),
		isWalletConnected() {
			return this.GET_DEX_WALLET !== null;
		},
		getAllRewards() {
			let total = 0
			this.GET_CLAIM_INFO?.detailed.forEach((item) => {
				if (item?.token.metadata.listed) {
					total += item?.available_usd
				}
			})

			if (Number.isInteger(total)) {
				return '$' + total
			} else {
				if (total > 1) {
					return '$' + total.toFixed(2)
				}
				return '$' + total.toFixed(4)
			}
			// this.GET_CLAIM_INFO?.aggregated.forEach((item) => {
			// 	total += item?.available_usd
			// })
		},
		getCashback() {
			// let find = this.GET_CLAIM_INFO?.aggregated.find((item) => item.type === 'cashback')
			// if (find) {
			// 	total = find?.available_usd
			// }
			let total = 0
			this.GET_CLAIM_INFO?.detailed.forEach((item) => {
				if (item?.token.metadata.listed && item?.type === 'cashback') {
					total += item?.available_usd
				}
			})

			if (Number.isInteger(total)) {
				return '$' + total
			} else {
				if (total > 1) {
					return '$' + total.toFixed(2)
				}
				return '$' + total.toFixed(4)
			}
		},
		getReferral() {
			// let find = this.GET_CLAIM_INFO?.aggregated.find((item) => item.type === 'referral')
			// if (find) {
			// 	total = find?.available_usd
			// }
			let total = 0
			this.GET_CLAIM_INFO?.detailed.forEach((item) => {
				if (item?.token.metadata.listed && item?.type === 'referral') {
					total += item?.available_usd
				}
			})

			if (Number.isInteger(total)) {
				return '$' + total
			} else {
				if (total > 1) {
					return '$' + total.toFixed(2)
				}
				return '$' + total.toFixed(4)
			}
		},
		getContests() {
			return '$0'
		}
	},
	methods: {
		...mapActions([
			'DEX_WALLET',
		]),
		connectWallet() {
			this.tonConnectUi.openModal()
		},
	},
	mounted() {
		// setInterval(() => {
		// 	let array = this.GET_CLAIM_STATS
		// 	if (array.length > 0) {
		// 		this.CLAIM_STATS(array)
		// 		array[0].available += 0.03
		// 		console.log(array)
		// 	}
		// }, 3_000)
	}
}
</script>

<style scoped>
.info {
	margin-bottom: 14px;
	padding: 12px;
	border-radius: 16px;
	border: 1px solid var(--iface-white4) !important;
}

.info__group {
	display: flex;
	gap: 12px;
	padding-bottom: 12px;
	margin-bottom: 12px;
	border-bottom: 1px dashed var(--iface-white10);
}

.info__group-text {
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	gap: 6px;
}

.info__image {
	display: block;
	width: 84px;
	height: 84px;
}

.info__heading {
	font-size: 24px;
	line-height: 28px;
	font-weight: 500;
}

.info__description {
	font-size: 13px;
	line-height: 15px;
	opacity: 0.7;
}

.info__stats {
	display: flex;
	gap: 0 8px;
}

.info__item {
	width: 100%;
	padding: 7px 24px;
	border-radius: 12px;
	border: 1px solid var(--iface-white8);
	background: var(--iface-white2);
}

.active-item {
	background: var(--iface-white2);
	border: 1px solid rgba(85, 255, 133, 0.26);
	background: rgba(85, 255, 133, 0.02);
}

.theme-light .active-item {
	border: 1px solid rgba(40, 205, 65, 0.20);
	background: rgba(40, 205, 65, 0.10);
}

.active-item .info__name {
	color: var(--main-green);
	opacity: 0.6;
}

.active-item .info__value {
	color: var(--main-green);
}

.info__name {
	margin-bottom: 6px;
	text-align: center;
	font-size: 12px;
	line-height: 14px;
	opacity: 0.6;
}

.info__value {
	text-align: center;
	font-size: 18px;
	line-height: 21px;
}

.dex__button {
	transition: .2s;
	width: 100%;
	border: none;
	outline: none;
	border-radius: 14px;
	background: var(--iface-accent-color);
	padding: 16px;
	max-height: 44px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dex__button:hover {
	background: var(--iface-accent-hover);
}

.dex__button:disabled {
	background: var(--main-btn-disabled);
}

.btn-text {
	font-size: 16px;
	line-height: 24px;
	font-family: Roboto, sans-serif;
	font-weight: 500;
	color: #fff;
}

.theme-light .dex__button:disabled .btn-text {
	color: #141414;
}

.dex__button:disabled .btn-text {
	opacity: 0.4;
}

.wallet-icon {
	margin-right: 5px;
	width: 20px;
	height: 20px;
	background: url("@/assets/dex/wallet-icon.svg") no-repeat;
	transition: background 0.2s ease;
}

.dex__button:hover .wallet-icon {
	background: url("@/assets/dex/wallet-filled.svg") no-repeat;
}

@media screen and (max-width: 880px) {
	.info__group {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.info__group-text {
		margin-top: 0;
		justify-content: center;
		align-items: center;
	}

	.info__description {
		text-align: center;
	}

	.info__stats {
		flex-wrap: wrap;
		gap: 10px;
	}

	.info__item {
		width: calc(50% - 5px);
	}
}

@media screen and (max-width: 1180px) {
	.info {
		margin-top: 14px;
	}
}

@media screen and (max-width: 880px) {
	.info {
		margin: 12px 0;
	}
}

@media screen and (max-width: 640px) {
	.dex__button {
		position: relative;
	}

	.dex__button {
		box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
	}
}

@media screen and (max-width: 460px) {

	.info__image {
		width: 84px;
		height: 84px;
	}
}
</style>