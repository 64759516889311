import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import components from '@/components/ui'
import { i18n } from "./translate";
// import * as Sentry from "@sentry/vue";


const app = createApp(App, {});

components.forEach(component => {
    app.component(component.name, component)
})

app.use(i18n);
app.use(router);
app.use(store);
app.mount("#app")