export function pinnedTokens() {
    return [
        {
            "address": "native"
        },
        {
            "address": "0:a5d12e31be87867851a28d3ce271203c8fa1a28ae826256e73c506d94d49edad"
        },
        {
            "address": "0:b113a994b5024a16719f69139328eb759596c38a25f59028b146fecdc3621dfe"
        },
        // {
        //     "address": "0:f6eb371de82aa9cfb5b22ca547f31fdc0fa0fbb41ae89ba84a73272ff0bf2157"
        // },
        // {
        //     "address": "0:0cd8a583a7d94dd18bf1bdf49b234af28c15f033bd2b6a4a4d2076ee1136ad45"
        // },
        // {
        //     "address": "0:beb5d4638e860ccf7317296e298fde5b35982f4725b0676dc98b1de987b82ebc"
        // },
        // {
        //     "address": "0:65aac9b5e380eae928db3c8e238d9bc0d61a9320fdc2bc7a2f6c87d6fedf9208"
        // },
        // {
        //     "address": "0:3690254dc15b2297610cda60744a45f2b710aa4234b89adb630e99d79b01bd4f"
        // },
        // {
        //     "address": "0:2f807ae445b1ba09fc90366a25d1cf0ccf67634f05c3a00bc9d6ae31164b37f4"
        // },
    ]
}