<template>
	<div class="sidebar">
		<div class="sidebar__content">
			<div class="sidebar__toggle-item"
				 :class="{active_toggle: getRouteName === 'Dex' || getRouteName === 'Limit'}"
				 @click="toggleAdditionalMenu"
			>
				<div class="sidebar__group">
					<div class="sidebar__icon dex-icon" :class="{ 'dex_filled': showAdditionalMenu }"></div>
					<p class="sidebar__title">{{ $t("dexSidebar.dex") }}</p>
				</div>
				<div class="sidebar__switch" :class="{ 'rotated_switch': showAdditionalMenu }"></div>
			</div>
			<transition name="slide">
				<div v-show="showAdditionalMenu" class="sidebar__additional-items">
					<!--						 :class="{ 'active_item': isActiveRoute('Dex') }"-->
					<!--						 @click="navigateTo('Dex')"-->
					<router-link :to="{name: 'Dex'}" :active-class="'active_item'" class="sidebar__item"
					>
						<p class="sidebar__title">{{ $t("dexNavigation.swap") }}</p>
						<div class="status-dot"></div>
					</router-link>
					<div class="sidebar__item disabled">
						<p class="sidebar__title disabled_text">{{ $t("dexNavigation.limit") }}</p>
						<p class="soon">{{ $t("dexSidebar.soon") }}</p>
					</div>
				</div>
			</transition>
			<div class="dividing-line"></div>
			<div class="sidebar__item underline_top underline_bottom disabled">
				<div class="sidebar__group">
					<div class="sidebar__icon portfolio-icon"></div>
					<p class="sidebar__title">{{ $t("dexSidebar.portfolio") }}</p>
				</div>
				<p class="soon">{{ $t("dexSidebar.soon") }}</p>
			</div>
			<div class="dividing-line"></div>
			<div class="sidebar__toggle-item"
				 :class="{active_toggle: getRouteName === 'Referral' || getRouteName === 'Cashback'}"
				 @click="toggleBenefits"
			>
				<div class="sidebar__group">
					<div class="sidebar__icon benefits-icon" :class="{ 'benefits_filled': showBenefits }"></div>
					<p class="sidebar__title">{{ $t("dexSidebar.benefits") }}</p>
				</div>
				<div class="sidebar__switch" :class="{ 'rotated_switch': showBenefits }"></div>
			</div>
			<transition name="slide">
				<div v-show="showBenefits" class="sidebar__additional-items">
					<!--					:class="{ 'active_item': getRouteName === 'Referral' }"-->
					<!--					@click="navigateTo('Referral')"-->
					<router-link :to="{name: 'Referral'}" :active-class="'active_item'" class="sidebar__item">
						<p class="sidebar__title">{{ $t("dexNavigation.referral") }}</p>
						<div class="status-dot"></div>
					</router-link>
					<div class="sidebar__item disabled"
						 :class="{ 'active_item': isActiveRoute('Cashback') }"
					>
<!--						@click="navigateTo('Cashback')"-->
						<p class="sidebar__title">{{ $t("dexNavigation.cashback") }}</p>
						<p class="soon">{{ $t("dexSidebar.soon") }}</p>
<!--						<div class="status-dot"></div>-->
					</div>
				</div>
			</transition>
			<!--			:class="{ 'active_item': getRouteName === 'Claim' }"-->
			<!--			@click="navigateTo('Claim')"-->
			<router-link :to="{name: 'Claim'}" :active-class="'active_item'" class="sidebar__item">
				<div class="sidebar__group">
					<div class="sidebar__icon claim-icon"></div>
					<p class="sidebar__title">{{ $t("dexSidebar.claim") }}</p>
				</div>
				<div class="status-dot"></div>
			</router-link>
			<div class="dividing-line"></div>
			<a class="sidebar__item underline_top" href="https://docs.swap.coffee/" target="_blank">
				<div class="sidebar__group">
					<div class="sidebar__icon docs-icon"></div>
					<p class="sidebar__title">{{ $t("dexSidebar.documentation") }}</p>
				</div>
				<div class="status-dot"></div>
			</a>
			<a class="sidebar__item" href="../white-paper.pdf" target="_blank">
				<div class="sidebar__group">
					<div class="sidebar__icon paper-icon"></div>
					<p class="sidebar__title">{{ $t("dexSidebar.whitePaper") }}</p>
				</div>
				<div class="status-dot"></div>
			</a>
			<!--			<div class="sidebar__item sidebar__item&#45;&#45;underlined-top disabled">-->
			<!--				<div class="sidebar__group">-->
			<!--					<div class="sidebar__icon sidebar__icon&#45;&#45;launchpad"></div>-->
			<!--					<p class="sidebar__title">{{ $t("dexSidebar.launchpad") }}</p>-->
			<!--				</div>-->
			<!--				<p class="soon">{{ $t("dexSidebar.soon") }}</p>-->
			<!--			</div>-->
		</div>
		<div class="dividing-line"></div>
		<button class="sidebar__settings-btn underline_top"
				:class="{ 'active_item': showSettingsMenu }"
				@click="showSettings"
		>
			<div class="sidebar__group">
				<div class="sidebar__icon settings-icon"></div>
				{{ $t('dexSidebar.settings') }}
			</div>
			<div class="status-dot"></div>
		</button>
	</div>
	<DexGlobalSettings
		v-show="showSettingsMenu"
		@closeSettings="closeSettings"
	/>
</template>
<script>

import computedMixins from "@/mixins/computedMixins";
import {defineAsyncComponent} from "vue";
import DexGlobalSettings from "./dex/DexGlobalSettings.vue";

export default {
	name: "Sidebar",
	components: {
		DexGlobalSettings: defineAsyncComponent(() => {
			return import("@/components/dex/DexGlobalSettings.vue")
		})
	},
	mixins: [computedMixins],
	data() {
		return {
			showAdditionalMenu: true,
			showBenefits: false,
			showSettingsMenu: false,
		}
	},
	methods: {
		toggleAdditionalMenu() {
			this.showAdditionalMenu = !this.showAdditionalMenu;
		},
		toggleBenefits() {
			this.showBenefits = !this.showBenefits
		},
		isActiveRoute(routeName) {
			return this.$route.name === routeName;
		},
		navigateTo(routeName) {
			this.$router.push({name: routeName});
		},
		showSettings() {
			this.showSettingsMenu = true
		},
		closeSettings() {
			this.showSettingsMenu = false
		}
	},
	mounted() {
		if (this.getRouteName === 'Referral' || this.getRouteName === 'Cashback') {
			this.showBenefits = true
			this.showAdditionalMenu = false
		}
	}
}
</script>
<style scoped>

.sidebar {
	position: fixed;
	left: 14px;
	top: 82px;
	border: 1px solid var(--iface-white8);
	border-radius: 16px;
	width: 226px;
	padding: 6px;
}

.sidebar__content {
	width: 100%;
}

.sidebar__title {
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.25px;
}

.sidebar__item {
	transition: .2s;
	padding: 12px;
	display: flex;
	gap: 12px;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	position: relative;
	opacity: 0.8;
}

.sidebar__item:hover:not(.disabled) {
	opacity: 1;
}

.disabled {
	padding: 9.5px 12px;
}

.sidebar__group {
	display: flex;
	align-items: center;
	gap: 0 12px;
}

.sidebar__icon {
	position: relative;
	display: block;
	width: 20px;
	height: 20px;
}

.theme-light .sidebar__icon {
	mix-blend-mode: difference;
	filter: invert(.8);
}

.portfolio-icon {
	background: url("@/assets/sidebar/portfolio-icon.svg") no-repeat;
	transition: background 0.2s ease;
}

.docs-icon {
	background: url("@/assets/sidebar/docs.svg") no-repeat;
	transition: background 0.2s ease;
}

.sidebar__item:active .docs-icon {
	background: url("@/assets/sidebar/docs-filled.svg") no-repeat;
}

.paper-icon {
	background: url("@/assets/sidebar/paper.svg") no-repeat;
	transition: background 0.2s ease;
}

.sidebar__item:active .paper-icon {
	background: url("@/assets/sidebar/paper-filled.svg") no-repeat;
}

.dex-icon {
	background: url("@/assets/sidebar/dex-outlined-icon.svg") no-repeat;
	transition: background 0.2s ease;
}

.active_toggle .dex-icon {
	background: url("@/assets/sidebar/dex-filled-icon.svg") no-repeat;
}

.benefits-icon {
	background: url("@/assets/sidebar/benefits.svg") no-repeat;
	transition: background 0.2s ease;
}

.active_toggle .benefits-icon {
	background: url("@/assets/sidebar/benefits-filled.svg") no-repeat;
}

.claim-icon {
	background: url("@/assets/sidebar/rewards-icon.svg") no-repeat;
	transition: background 0.2s ease;
}

.sidebar__item:active .claim-icon {
//background: url("@/assets/sidebar/rewards-filled-icon.svg") no-repeat;
}

.active_item .claim-icon {
	background: url("@/assets/sidebar/rewards-filled-icon.svg") no-repeat;
}

.sidebar__toggle-item {
	transition: .2s;
	padding: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 12px;
	cursor: pointer;
	border-radius: 14px;
	opacity: 0.8;
}

.sidebar__toggle-item:hover {
	opacity: 1;
}

.active_toggle {
	background: var(--iface-white4);
	opacity: 1;
}

/*.sidebar__item--underlined {
	padding-bottom: 20px;
	border-bottom: 1px solid var(--iface-white6);
}*/

.sidebar__switch {
	width: 16px;
	height: 16px;
	display: block;
	background: url("@/assets/dex/arrow-down.svg") no-repeat;
	transition: transform 0.3s ease;
}

.theme-light .sidebar__switch {
	mix-blend-mode: difference;
	filter: invert(.8);
}

.rotated_switch {
	transform: rotateX(180deg);
}

.sidebar__additional-items {
	margin-top: 2px;
	padding-left: 32px;
}

.active_item {
	background: var(--iface-white4);
	border-radius: 14px;
	opacity: 1;
}

/*.active_item::after {
	content: '';
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	width: 4px;
	height: 4px;
	background-color: var(--main-green);
	border-radius: 50%;
}*/

.sidebar__icon--launchpad {
	background: url("@/assets/sidebar/launchpad-icon.svg") no-repeat;
	transition: background 0.2s ease;
}

.sidebar__item:hover .sidebar__icon--launchpad {
	background: url("@/assets/sidebar/launchpad-filled-icon.svg") no-repeat;
}

.sidebar__icon--launchpad::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: url("@/assets/sidebar/launchpad-filled-icon.svg") no-repeat;
	opacity: 0;
	transition: opacity 0.2s ease;
}

.sidebar__item:hover .sidebar__icon--launchpad::before {
	opacity: 1;
}

/*.sidebar__item::after {
	content: '';
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	width: 4px;
	height: 4px;
	background-color: white;
	border-radius: 50%;
	opacity: 0;
	transition: opacity 0.2s ease;
}*/

/*.sidebar__item:hover::after {
	opacity: 1;
}*/

/*.active_item::after {
	background-color: #55FF85;
	opacity: 1;
}*/

.sidebar__settings-btn {
	transition: .2s;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: none;
	outline: none;
	padding: 12px;
	background: transparent;
	font-family: Roboto, sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.25px;
	opacity: 0.8;
}

.sidebar__settings-btn:hover {
	opacity: 1;
}

.settings-icon {
	background: url("@/assets/sidebar/settings.svg") no-repeat;
	transition: background 0.2s ease;
}

.sidebar__settings-btn:active .settings-icon {
	background: url("@/assets/sidebar/settings-filled.svg") no-repeat;
}

.underline_top {
//margin-top: 10px; //border-top: 1px solid var(--iface-white6);
}

.underline_bottom {
//margin-bottom: 10px; //border-bottom: 1px solid var(--iface-white6);
}

.dividing-line {
	margin: 6px 12px;
	width: calc(100% - 24px);
	height: 1px;
	background: var(--iface-white6);
}

.soon {
	border-radius: 100px;
	padding: 5px 10px;
	background: var(--iface-white6);
	font-size: 13px;
	line-height: 15px;
	white-space: nowrap;
}

.disabled .sidebar__title,
.disabled:hover .sidebar__title,
.disabled .sidebar__icon,
.disabled:hover .sidebar__icon {
	opacity: 0.5;
}

.disabled:hover::after {
	opacity: 0;
}

.theme-light svg path {
	fill: #141414;
}

.status-dot {
	transition: .2s;
	width: 4px;
	height: 4px;
	background: var(--main-text-color);
	border-radius: 100px;
	opacity: 0;
}

.sidebar__item:hover .status-dot {
	opacity: 1;
}

.sidebar__item:active .status-dot {
	opacity: 1;
	background: var(--main-green);
}

.sidebar__settings-btn:hover .status-dot {
	opacity: 1;
}

.sidebar__settings-btn:active .status-dot {
	opacity: 1;
	background: var(--main-green);
}

.active_item .status-dot {
	opacity: 1;
	background: var(--main-green);
}

@media screen and (max-width: 1180px) {
	.sidebar {
		position: static;
		top: 60px;
		bottom: 0;
		border: none;
		width: 100%;
		padding: 0;
	}

	.theme-light .sidebar {
		border: none;
	}

	.sidebar__content {
		margin-top: 0;
	}

	.sidebar__settings-btn {
		margin-top: 10px;
		padding-top: 12px;
	}

	/*.sidebar__toggle-item {
		max-width: 300px;
	}

	.sidebar__additional-items {
		max-width: 300px;
	}*/
}
</style>