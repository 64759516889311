<template>
	<a href="https://t.me/whale/?start=fb2f3d06eecafe67" target="_blank" class="link">
		<div class="advertising animate-bg claim_banner gradient"
			 :class="{claim_banner: getRouteName === 'Claim' || getRouteName === 'Referral'}"
		>
			<div class="advertising__group">
				<div class="advertising__icon-template">
					<div class="advertising__icon" :id="setId()"></div>
				</div>
				<p class="advertising__text">$10,000,000 worth of $WHALE Token Airdrop! Invite friends, Earn tokens!</p>
				<div class="advertising__column">
					<p class="advertising__title">$10,000,000 worth of $WHALE Token Airdrop!</p>
					<p class="advertising__desc">Invite friends, Earn tokens!</p>
				</div>
			</div>
			<svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
				<path d="M12.9463 12L8.87301 7.92701C8.73468 7.78851 8.66385 7.61443 8.66051 7.40476C8.65735 7.19526 8.72818 7.01801 8.87301 6.87301C9.01801 6.72818 9.19368 6.65576 9.40001 6.65576C9.60634 6.65576 9.78201 6.72818 9.92701 6.87301L14.4213 11.3673C14.5148 11.4609 14.5808 11.5597 14.6193 11.6635C14.6578 11.7673 14.677 11.8795 14.677 12C14.677 12.1205 14.6578 12.2327 14.6193 12.3365C14.5808 12.4403 14.5148 12.5391 14.4213 12.6328L9.92701 17.127C9.78851 17.2653 9.61443 17.3362 9.40476 17.3395C9.19526 17.3427 9.01801 17.2718 8.87301 17.127C8.72818 16.982 8.65576 16.8063 8.65576 16.6C8.65576 16.3937 8.72818 16.218 8.87301 16.073L12.9463 12Z" fill="#FF8E26"/>
			</svg>
		</div>
	</a>
</template>

<script>
import lottie from "lottie-web";
import computedMixins from "@/mixins/computedMixins";

export default {
	name: "AdvertisingBanner",
	mixins: [computedMixins],
	data() {
		return {}
	},
	methods: {
		setId() {
			return 'adv_' + this.getRouteName.toLowerCase()
		}
	},
	mounted() {
		let container = document.getElementById('adv_' + this.getRouteName.toLowerCase())

		setTimeout(() => {
			lottie.loadAnimation({
				container: container, // the dom element that will contain the animation
				renderer: 'svg',
				loop: true,
				autoplay: true,
				name: 'adv',
				animationData: require('@/assets/lottie/whale.json')
			});
		}, 10)
	},
	unmounted() {
		lottie.destroy('adv')
	}
}
</script>

<style scoped>

.link {
	display: block;
	margin-bottom: 14px;
}

.advertising {
	gap: 0 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 6px;
	width: 100%;
	border-radius: 16px;
	cursor: pointer;
}

.gradient {
	position: relative;
	background: linear-gradient(90deg, rgba(255, 194, 38, 0.1) 0%, rgba(255, 142, 38, 0.1) 100%);
	background-size: 150%;
	z-index: 1;
	animation: 3s ease changeBackground infinite alternate;
}

.gradient::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: inherit;
	padding: 1px; /* Толщина бордера */
	background: linear-gradient(90deg, rgba(255, 194, 38, 0.36) 0%, rgba(255, 142, 38, 0.36) 100%);
	background-size: 150%;
	mask: linear-gradient(#fff 0, #fff 0) content-box, linear-gradient(#fff 0, #fff 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
	z-index: -1;
	animation: 3s ease changeBackground infinite alternate;
}

.advertising__group {
	display: flex;
	align-items: center;
}

.advertising__icon-template {
	width: 36px;
	height: 36px;
	padding: 6px;
	margin-right: 10px;
	border-radius: 12px;
	background: rgba(255, 142, 38, 0.10);
	display: flex;
	justify-content: center;
	align-items: center;
}

.theme-light .advertising__icon-template {
	background: rgba(255, 142, 38, 0.10);
}

.advertising__text {
	font-size: 15px;
	font-family: Roboto, sans-serif;
	font-weight: 500;
	background: linear-gradient(90deg, #FFC226 0%, #FF8E26 100%);
	background-size: 150%;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: 3s ease changeBackground infinite alternate;
}

.advertising__title,
.advertising__desc {
	display: none;
	background: linear-gradient(90deg, #FFC226 0%, #FF8E26 100%);
	background-size: 150%;
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: 3s ease changeBackground infinite alternate;
}

.advertising__icon {
	max-width: 24px;
	width: 24px;
	height: 24px;
}

.arrow-icon {
	min-width: 24px;
}

/*
.advertising-btn__link {
	display: flex;
	align-items: center;
}


.advertising__icon path {
	fill: var(--adv-color);
}
 */

@keyframes changeBackground {
	0% {
		background-position: 0% 0;
	}
	50% {
		background-position: 100% 0;
	}
	100% {
		background-position: 0% 0;
	}
}

@media screen and (max-width: 1180px) {
	.link {
		padding: 14px;
		border-bottom: 1px solid var(--iface-white8);
		margin-bottom: 0;
	}
}

@media screen and (max-width: 880px) {
	.link {
		padding: 12px 10px;
	}

	.advertising__text {
		display: none;
	}

	.advertising__desc {
		display: block;
		font-size: 13px;
		line-height: 16px;
	}

	.advertising__title {
		display: block;
		font-size: 13px;
		line-height: 20px;
		font-family: Roboto, sans-serif;
		font-weight: 500;
	}
}

/*@media screen and (max-width: 860px) {
	.advertising {
		padding: 12px 10px;
		margin-bottom: 0;
		border-radius: 0;
		border-right: none;
		border-left: none;
	}
}*/

</style>
