export default {
    state: () => ({
        technicalWorks: false,
    }),
    mutations: {
        SET_TECHNICAL_WORKS(state, value) {
            state.technicalWorks = value;
        },
        RESET_STORE(state) {
            state.technicalWorks = false;
        }
    },
    actions: {
        TECHNICAL_WORKS({commit}, item) {
            commit('SET_TECHNICAL_WORKS', item)
        },
        RESET_TECHNICAL_WORKS({commit}) {
            commit('RESET_STORE')
        }
    },
    getters: {
        GET_TECHNICAL_WORKS_STATUS: state => {
            return state.technicalWorks;
        }
    }

}