import { createI18n } from "vue-i18n";
import ru from '@/translate/ru.json';
import en from '@/translate/en.json';
import ua from '@/translate/ua.json';
import es from '@/translate/es.json';
import zh from '@/translate/zh.json';
import fr from '@/translate/fr.json';
import fa from '@/translate/fa.json';

const messages = {
    ru, en, ua, es, zh, fr, fa
};

export const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages
});

