<template>
	<main-color class="tokens">
		<h3 class="tokens__caption">{{ $t('claimCenter.caption') }}</h3>
		<div class="tokens__content">
			<p class="tokens__description">{{ $t('claimCenter.text') }}</p>
			<div v-if="selectList.length === 0" class="tokens__controls">
				<button @click="selectAll" class="tokens__btn select-btn">
					<svg class="btn-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<path d="M8.8173 11.7885L6.88147 9.85242C6.76605 9.73714 6.62098 9.67811 6.44626 9.67533C6.27168 9.6727 6.12397 9.73172 6.00313 9.85242C5.88244 9.97325 5.82209 10.1196 5.82209 10.2916C5.82209 10.4635 5.88244 10.6099 6.00313 10.7308L8.29001 13.0176C8.4407 13.1682 8.61647 13.2435 8.8173 13.2435C9.01814 13.2435 9.1939 13.1682 9.34459 13.0176L13.9808 8.38138C14.0961 8.26596 14.1551 8.12089 14.1579 7.94617C14.1606 7.77158 14.1015 7.62388 13.9808 7.50304C13.86 7.38235 13.7136 7.322 13.5417 7.322C13.3697 7.322 13.2233 7.38235 13.1025 7.50304L8.8173 11.7885ZM10.0015 17.9166C8.90647 17.9166 7.87723 17.7088 6.91376 17.2933C5.95029 16.8777 5.11223 16.3137 4.39959 15.6014C3.68695 14.889 3.12272 14.0513 2.70688 13.0883C2.29119 12.1252 2.08334 11.0962 2.08334 10.0014C2.08334 8.90638 2.29112 7.87714 2.70668 6.91367C3.12223 5.9502 3.68619 5.11214 4.39855 4.3995C5.11091 3.68686 5.94862 3.12263 6.91168 2.70679C7.87473 2.2911 8.90369 2.08325 9.99855 2.08325C11.0936 2.08325 12.1228 2.29103 13.0863 2.70658C14.0497 3.12214 14.8878 3.6861 15.6004 4.39846C16.3131 5.11082 16.8773 5.94853 17.2931 6.91159C17.7088 7.87464 17.9167 8.9036 17.9167 9.99846C17.9167 11.0935 17.7089 12.1227 17.2933 13.0862C16.8778 14.0496 16.3138 14.8877 15.6015 15.6003C14.8891 16.313 14.0514 16.8772 13.0883 17.293C12.1253 17.7087 11.0963 17.9166 10.0015 17.9166ZM10 16.6666C11.8611 16.6666 13.4375 16.0208 14.7292 14.7291C16.0208 13.4374 16.6667 11.861 16.6667 9.99992C16.6667 8.13881 16.0208 6.56242 14.7292 5.27075C13.4375 3.97909 11.8611 3.33325 10 3.33325C8.1389 3.33325 6.56251 3.97909 5.27084 5.27075C3.97918 6.56242 3.33334 8.13881 3.33334 9.99992C3.33334 11.861 3.97918 13.4374 5.27084 14.7291C6.56251 16.0208 8.1389 16.6666 10 16.6666Z" fill="#E8EAED"/>
					</svg>
					Select All
				</button>
				<button class="tokens__btn mobile-btn"
						@click="getTokensByType('all')">
					All
				</button>
				<button class="tokens__btn mobile-btn"
						@click="getTokensByType('cashback')">
					Cashback
				</button>
<!--				<button class="tokens__btn mobile-btn"-->
<!--						@click="getTokensByType('contests')">-->
<!--					Contests-->
<!--				</button>-->
				<button class="tokens__btn mobile-btn"
						@click="getTokensByType('referral')"
				>
					Referral
				</button>
			</div>
			<div class="tokens__controls" v-else>
				<button class="tokens__btn claim-btn"
						@click="claimAction"
				>
					{{ selectList.length === 1 ? "Claim now" : "Claim All" }}
					<img src="@/assets/claim/right-arrow.svg" alt="arrow" class="btn-arrow">
				</button>
				<!--			<button class="tokens__btn swap-btn"-->
				<!--					v-if="selectList.length === 1"-->
				<!--					@click="swapToTon"-->
				<!--			>-->
				<!--				Swap to TON-->
				<!--				<svg class="btn-arrow" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">-->
				<!--					<g id="Icon-CaretRight">-->
				<!--						<path id="Vector" d="M11.3538 8.35378L6.35375 13.3538C6.3073 13.4002 6.25215 13.4371 6.19145 13.4622C6.13076 13.4874 6.0657 13.5003 6 13.5003C5.93431 13.5003 5.86925 13.4874 5.80856 13.4622C5.74786 13.4371 5.69271 13.4002 5.64625 13.3538C5.5998 13.3073 5.56295 13.2522 5.53781 13.1915C5.51267 13.1308 5.49973 13.0657 5.49973 13C5.49973 12.9343 5.51267 12.8693 5.53781 12.8086C5.56295 12.7479 5.5998 12.6927 5.64625 12.6463L10.2931 8.00003L5.64625 3.35378C5.55243 3.25996 5.49973 3.13272 5.49973 3.00003C5.49973 2.86735 5.55243 2.7401 5.64625 2.64628C5.74007 2.55246 5.86732 2.49976 6 2.49976C6.13269 2.49976 6.25993 2.55246 6.35375 2.64628L11.3538 7.64628C11.4002 7.69272 11.4371 7.74786 11.4623 7.80856C11.4874 7.86926 11.5004 7.93433 11.5004 8.00003C11.5004 8.06574 11.4874 8.13081 11.4623 8.1915C11.4371 8.2522 11.4002 8.30735 11.3538 8.35378Z" fill="white"/>-->
				<!--					</g>-->
				<!--				</svg>-->
				<!--			</button>-->
				<button class="tokens__btn unselect-btn"
						v-if="selectList.length > 0"
						@click="unselect"
				>
					<svg class="btn-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
						<g opacity="0.8">
							<path d="M10 10.8781L5.77251 15.1058C5.65709 15.2211 5.51202 15.2801 5.3373 15.2829C5.16272 15.2856 5.01501 15.2265 4.89418 15.1058C4.77348 14.985 4.71313 14.8386 4.71313 14.6667C4.71313 14.4947 4.77348 14.3483 4.89418 14.2275L9.12188 10L4.89418 5.77251C4.7789 5.65709 4.71987 5.51202 4.71709 5.3373C4.71445 5.16272 4.77348 5.01501 4.89418 4.89418C5.01501 4.77348 5.1614 4.71313 5.33334 4.71313C5.50529 4.71313 5.65168 4.77348 5.77251 4.89418L10 9.12188L14.2275 4.89418C14.3429 4.7789 14.488 4.71987 14.6627 4.71709C14.8373 4.71445 14.985 4.77348 15.1058 4.89418C15.2265 5.01501 15.2869 5.1614 15.2869 5.33334C15.2869 5.50529 15.2265 5.65168 15.1058 5.77251L10.8781 10L15.1058 14.2275C15.2211 14.3429 15.2801 14.488 15.2829 14.6627C15.2856 14.8373 15.2265 14.985 15.1058 15.1058C14.985 15.2265 14.8386 15.2869 14.6667 15.2869C14.4947 15.2869 14.3483 15.2265 14.2275 15.1058L10 10.8781Z" fill="#E8EAED"/>
						</g>
					</svg>
					Unselect
				</button>
			</div>
			<div class="skeleton-wrapper"
				 v-if="showLoader"
			>
				<div class="skeleton-large table-skeleton"
					 v-for="item in 4"
					 :key="item"
				></div>
			</div>
			<div class="tokens__empty"
				 v-else-if="filteredRewards.length === 0"
			>
				<p class="tokens__empty-text">{{ $t("claimCenter.empty") }}</p>
			</div>
			<div class="tokens__table"
				v-else
			>
				<div class="tokens__column-list">
					<p class="tokens__column-name">Token</p>
					<p class="tokens__column-name">Value</p>
					<p class="tokens__column-name">Amount</p>
					<p class="tokens__column-name">Price</p>
					<div class="tokens__column-group">
						<p class="tokens__column-value">Source</p>
						<div class="icon-wrapper"
							 @mouseenter="showTooltip = true"
							 @mouseleave="showTooltip = false"
						>
							<!--						<img src="@/assets/dex/details-info.svg" alt="" class="dex__info-icon">-->
							<svg class="tokens__info-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<g opacity="0.5">
									<path d="M8 1.5C6.71442 1.5 5.45772 1.88122 4.3888 2.59545C3.31988 3.30968 2.48676 4.32484 1.99479 5.51256C1.50282 6.70028 1.37409 8.00721 1.6249 9.26809C1.8757 10.529 2.49477 11.6872 3.40381 12.5962C4.31285 13.5052 5.47104 14.1243 6.73192 14.3751C7.99279 14.6259 9.29973 14.4972 10.4874 14.0052C11.6752 13.5132 12.6903 12.6801 13.4046 11.6112C14.1188 10.5423 14.5 9.28558 14.5 8C14.4982 6.27665 13.8128 4.62441 12.5942 3.40582C11.3756 2.18722 9.72335 1.50182 8 1.5ZM7.75 4.5C7.89834 4.5 8.04334 4.54399 8.16668 4.6264C8.29002 4.70881 8.38615 4.82594 8.44291 4.96299C8.49968 5.10003 8.51453 5.25083 8.48559 5.39632C8.45665 5.5418 8.38522 5.67544 8.28033 5.78033C8.17544 5.88522 8.04181 5.95665 7.89632 5.98559C7.75083 6.01453 7.60003 5.99968 7.46299 5.94291C7.32595 5.88614 7.20881 5.79001 7.1264 5.66668C7.04399 5.54334 7 5.39834 7 5.25C7 5.05109 7.07902 4.86032 7.21967 4.71967C7.36032 4.57902 7.55109 4.5 7.75 4.5ZM8.5 11.5C8.23479 11.5 7.98043 11.3946 7.7929 11.2071C7.60536 11.0196 7.5 10.7652 7.5 10.5V8C7.36739 8 7.24022 7.94732 7.14645 7.85355C7.05268 7.75979 7 7.63261 7 7.5C7 7.36739 7.05268 7.24021 7.14645 7.14645C7.24022 7.05268 7.36739 7 7.5 7C7.76522 7 8.01957 7.10536 8.20711 7.29289C8.39465 7.48043 8.5 7.73478 8.5 8V10.5C8.63261 10.5 8.75979 10.5527 8.85356 10.6464C8.94732 10.7402 9 10.8674 9 11C9 11.1326 8.94732 11.2598 8.85356 11.3536C8.75979 11.4473 8.63261 11.5 8.5 11.5Z" fill="white"/>
								</g>
							</svg>
							<transition name="tooltip">
								<tooltip-wrapper class="tokens__tooltip"
												 v-show="showTooltip === true"
												 @hiddenTooltip="showTooltip = false"
								>
									{{ $t("claimCenter.tooltip") }}
								</tooltip-wrapper>
							</transition>
						</div>
					</div>
					<p class="tokens__column-name">Liquidity</p>
				</div>
				<div class="tokens__info">
					<p class="tokens__info-name">$ Price</p>
					<div class="dividing-line"></div>
					<svg class="tokens__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
						<path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M3.24981 3.61111C3.11222 3.61111 3.02649 3.76615 3.09582 3.8896L6.46518 9.88944V3.61111H3.24981ZM7.53483 3.61111V9.88944L10.9042 3.8896C10.9735 3.76615 10.8878 3.61111 10.7502 3.61111H7.53483ZM2.17188 4.44946C1.68657 3.58528 2.28666 2.5 3.24981 2.5H10.7502C11.7133 2.5 12.3134 3.58528 11.8281 4.44946L7.46197 12.2244C7.36613 12.3951 7.19023 12.5 7 12.5C6.80978 12.5 6.63388 12.3951 6.53803 12.2244L2.17188 4.44946Z" fill="white"/>
					</svg>
					<p class="tokens__info-name">Liquidity</p>
					<div class="dividing-line"></div>
					<svg class="tokens__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
						<g opacity="0.4">
							<path d="M12.6286 11.5938C11.7957 10.1539 10.5121 9.12135 9.01426 8.6319C9.75518 8.19082 10.3308 7.51872 10.6528 6.71882C10.9748 5.91891 11.0253 5.03543 10.7966 4.20405C10.5678 3.37266 10.0725 2.63935 9.38667 2.11672C8.70083 1.59408 7.86239 1.31104 7.00012 1.31104C6.13784 1.31104 5.29941 1.59408 4.61357 2.11672C3.92773 2.63935 3.43241 3.37266 3.20368 4.20405C2.97495 5.03543 3.02545 5.91891 3.34742 6.71882C3.6694 7.51872 4.24505 8.19082 4.98598 8.6319C3.48809 9.1208 2.20457 10.1533 1.37168 11.5938C1.34114 11.6436 1.32088 11.699 1.3121 11.7568C1.30332 11.8145 1.3062 11.8734 1.32056 11.9301C1.33493 11.9867 1.36049 12.0399 1.39574 12.0865C1.43099 12.1331 1.47521 12.1721 1.5258 12.2013C1.57639 12.2306 1.63232 12.2494 1.69029 12.2566C1.74826 12.2639 1.8071 12.2594 1.86333 12.2436C1.91956 12.2277 1.97205 12.2008 2.0177 12.1643C2.06334 12.1279 2.10122 12.0826 2.1291 12.0313C3.15941 10.2506 4.98051 9.18752 7.00012 9.18752C9.01973 9.18752 10.8408 10.2506 11.8711 12.0313C11.899 12.0826 11.9369 12.1279 11.9825 12.1643C12.0282 12.2008 12.0807 12.2277 12.1369 12.2436C12.1931 12.2594 12.252 12.2639 12.3099 12.2566C12.3679 12.2494 12.4238 12.2306 12.4744 12.2013C12.525 12.1721 12.5692 12.1331 12.6045 12.0865C12.6397 12.0399 12.6653 11.9867 12.6797 11.9301C12.694 11.8734 12.6969 11.8145 12.6881 11.7568C12.6794 11.699 12.6591 11.6436 12.6286 11.5938ZM3.93762 5.25002C3.93762 4.64432 4.11723 4.05222 4.45374 3.54859C4.79025 3.04496 5.26855 2.65244 5.82815 2.42064C6.38775 2.18885 7.00351 2.1282 7.59758 2.24637C8.19165 2.36454 8.73733 2.65621 9.16563 3.08451C9.59393 3.51281 9.8856 4.05849 10.0038 4.65256C10.1219 5.24663 10.0613 5.86239 9.8295 6.42199C9.5977 6.98159 9.20518 7.45989 8.70155 7.7964C8.19793 8.13291 7.60582 8.31252 7.00012 8.31252C6.18816 8.31166 5.4097 7.98872 4.83556 7.41458C4.26142 6.84044 3.93849 6.06198 3.93762 5.25002Z" fill="white"/>
						</g>
					</svg>
					<p class="tokens__info-name">Holders amount</p>
				</div>
				<div class="tokens__list">
					<ClaimTokenItem
						v-for="(item, index) in filteredRewards"
						:key="index"
						:item="item"
						:class="'token_' + (index + 1)"
						:selected="isSelected(item)"
						@select="selectTokenAction"
					/>
				</div>
			</div>
		</div>
	</main-color>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ClaimTokenItem from "@/components/claim/ClaimTokenItem.vue";
import computedMixins from "@/mixins/computedMixins";
import {Address} from "@ton/core";
import TooltipWrapper from "@/components/ui/TooltipWrapper.vue";

export default {
	name: "ClaimTokens",
	mixins: [computedMixins],
	components: {
		TooltipWrapper,
		ClaimTokenItem
	},
	props: {
		cleanSelected: {
			type: Boolean,
			default() {
				return false
			}
		}
	},
	data() {
		return {
			setCount: 0,
			showTooltip: false,
			showLoader: false,
			tokensPage: 1,
			addTokensCount: 50,
			tokensList: [],
			selectList: [],
		}
	},
	computed: {
		...mapGetters([
			'GET_DEX_WALLET',
			'GET_TON_TOKENS',
			'GET_PROOF_VERIFICATION',
			'GET_CLAIM_INFO',
		]),
		observer() {
			return new IntersectionObserver((entries) => this.showMoreTokens(entries));
		},
		getUserRewards() {
			let array = []
			this.tokensList.forEach((item) => {
				let detailedAddress = item.token.address.address
				let rawAddress = 'native'
				if (detailedAddress !== 'native') {
					rawAddress = Address.parseFriendly(detailedAddress).address.toRawString()
				}
				let findItem = this.GET_TON_TOKENS.find((find) => find?.address === rawAddress)
				if (findItem) {
					let params = [item, detailedAddress, rawAddress, findItem]
					let asset = this.setToken(params)
					array.push(asset)
				}
			})
			return array
		},
		filteredRewards() {
			return this.getUserRewards.filter((item) => item?.amount > 0)
		}
	},
	methods: {
		...mapActions([
			'CLAIM_INFO',
		]),
		getTarget() {
			return document.querySelector(`.token_${this.filteredRewards.length}`)
		},
		setObserver() {
			setTimeout(() => {
				if (this.getTarget() && this.tokensList.length >= this.addTokensCount * this.tokensPage) {
					this.observer.observe(this.getTarget());
					this.setCount++
				}
			}, 500)
		},
		setToken(params) {
			const [item, friendly, rawAddress, findItem] = params
			return {
				"image": findItem !== null ? findItem?.image : item?.token?.metadata.image_url,
				"name": findItem !== null ? findItem?.name : item?.token?.metadata.name,
				"symbol": findItem !== null ? findItem?.symbol : item?.token?.metadata.symbol,
				"address": rawAddress,
				"friendly_address": friendly,
				"wallet_address": item?.wallet,
				"price_usd": findItem !== null ? findItem?.price_usd : findItem,
				"price_change_24h": findItem !== null ? findItem?.price_change_24h : findItem,
				"tvl": findItem !== null ? findItem.tvl : findItem,
				"holders_count": findItem !== null ? findItem?.holders_count : findItem,
				"claim_type": item?.type,
				"amount": item?.available,
				"value": item?.available_usd,
				"decimals": findItem !== null ? findItem?.decimals : item?.token?.metadata.decimals
			}
		},
		async showMoreTokens(entries) {
			let count = 0
			if (entries[0].isIntersecting) {
				let oldValue = this.filteredRewards
				this.tokensPage++ // Увеличить кол-во токенов
				let detailed = await this.getClaimInfo()
				this.observer.disconnect();

				if (detailed.length > 0 && this.filteredRewards.length > oldValue.length) {
					// Привязать слушатель по новому индексу
					setTimeout(() => {
						if (this.getTarget) {
							this.observer.observe(this.getTarget());
						}
					}, 500)
				} else {
					// В случае если токенов всего меньше чем текущий count + 50
					// this.tokensPage = this.getUserRewards.length
					this.observer.disconnect()
				}
			} else {
				count++
				if (count > 8) {
					this.observer.disconnect()
				}
			}
		},
		async getTokensByType(type) {
			try {
				this.selectList = []
				this.tokensPage = 1
				let opts = {
					page: this.tokensPage,
					size: this.addTokensCount
				}
				let res = await this.dexApiV2.getClaimInfo(this.GET_DEX_WALLET?.address, type, this.GET_PROOF_VERIFICATION, opts)
				console.log('getTokensByType', res)
				this.tokensList = res?.detailed

				this.observer.disconnect()
				this.setObserver()
			} catch (err) {
				if (err.response.status === 403) {
					setTimeout(() => {
						this.disconnectWallet()
						this.tokensPage = 1
					}, 1000)
				}
				console.error(err)
			}
		},
		async getClaimInfo() {
			try {
				let opts = {
					page: this.tokensPage,
					size: this.addTokensCount
				}
				let res = await this.dexApiV2.getClaimInfo(this.GET_DEX_WALLET?.address, 'all', this.GET_PROOF_VERIFICATION, opts)
				console.log('getClaimInfo', res)
				this.tokensList = this.tokensList.concat(res?.detailed)
				res.detailed = this.tokensList
				await this.CLAIM_INFO(res)

				return res?.detailed
			} catch (err) {
				if (err.response.status === 403) {
					setTimeout(() => {
						this.disconnectWallet()
						this.tokensPage = 1
					}, 1000)
				}
				console.error(err)
			}
		},
		getValue(token) {
			let amount = token?.amount / Math.pow(10, token?.decimals)
			return amount * token.price_usd
		},
		// selectGroup(value) {
		// 	this.selectList = []
		// 	this.filteredRewards.forEach((item) => {
		// 		if (item?.claim_type === value) {
		// 			this.selectList.push(item)
		// 		}
		// 	})
		// },
		selectAll() {
			if (this.selectList.length) {
				if (this.selectList.length === this.tokensList.length) {
					this.selectList = []
				}
			} else {
				this.selectList = [...this.filteredRewards];
			}
		},
		selectTokenAction(selectedToken) {
			const index = this.selectList.findIndex(token => token.address === selectedToken.address && token.amount === selectedToken.amount);
			if (index !== -1) {
				this.selectList.splice(index, 1);
			} else {
				this.selectList.push(selectedToken);
			}
		},
		isSelected(item) {
			return this.selectList.some((token) => token.address === item.address && token.amount === item.amount);
		},
		unselect() {
			this.selectList = []
		},
		claimAction() {
			this.$emit('claimAction', {
				"selectList": this.selectList
			})
		},
		swapToTon() {
			this.$emit('swapToTon', {
				"selectList": this.selectList
			})
		},
	},
	mounted() {
		if (this.GET_CLAIM_INFO === null) {
			this.showLoader = true
		}
	},
	unmounted() {
		this.observer.disconnect();
	},
	watch: {
		cleanSelected: {
			handler() {
				if (this.cleanSelected === true) {
					this.selectList = []
				}
			}
		},
		GET_CLAIM_INFO: {
			handler() {
				if (this.GET_CLAIM_INFO !== null) {
					if (this.setCount === 0) {
						this.tokensList = this.GET_CLAIM_INFO?.detailed
						setTimeout(() => {
							this.showLoader = false
						}, 200)
						this.setObserver()
					}
				}
			}
		},
	}
}
</script>

<style scoped>
.tokens {
	padding: 12px 0;
	border-radius: 16px;
	border: 1px solid var(--iface-white4) !important;
}

.tokens__empty {
	padding: 0 12px;
}

.tokens__empty-text {
	font-size: 13px;
	line-height: 15px;
	opacity: 0.7;
}

.tokens__caption {
	padding: 0 12px;
	font-weight: 500;
	margin-bottom: 6px;
	font-size: 16px;
	line-height: 19px;
}

.tokens__description {
	padding: 0 12px;
	margin-bottom: 16px;
	font-size: 13px;
	line-height: 15px;
	opacity: 0.7;
}

.btn-arrow {
	margin-left: 12px;
}

.tokens__controls {
	padding: 0 12px 8px 12px;
	margin-bottom: 8px;
	//padding-bottom: 8px;
	display: flex;
	gap: 0 8px;
}

.tokens__btn {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 16px;
	border: 1px solid transparent;
	background: var(--iface-white4);
	border-radius: 12px;
	font-size: 14px;
	max-height: 44px;
	transition: background 0.2s, color 0.2s, border 0.2s;
	white-space: nowrap;
}

.tokens__btn:hover {
	background: var(--iface-white8);
}

.select-btn {
	padding: 12px 16px 12px 12px;
}


.claim-btn {
	min-width: 140px;
	background: var(--iface-accent-color);
	border: 1px solid transparent;
	color: #fff;
	padding: 12px 12px 12px 16px;
}

.claim-btn:hover {
	background: var(--iface-accent-hover)
}

.swap-btn {
	min-width: 140px;
	background: none;
	color: var(--iface-accent-color);
	position: relative;
	padding: 12px 12px 12px 16px;
	border: none;
	transition: border 0.3s, color 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;
}

.swap-btn {
	background: none;
	border: 1px solid var(--iface-accent-color);
}

.swap-btn:hover {
	color: var(--iface-accent-hover);
	border: 1px solid var(--iface-accent-hover);
	background: transparent;
}

.swap-btn .btn-arrow path {
	fill: var(--iface-accent-color);
}

.swap-btn:hover .btn-arrow path {
	fill: var(--iface-accent-hover);
}

.unselect-btn {
	padding: 12px 16px 12px 12px;
}

.btn-icon {
	margin-right: 8px;
}

.tokens__table {
	padding: 0 12px;
}

.tokens__column-list {
	margin-bottom: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 0 10px;
	padding: 0 14px;
}

.tokens__info {
	display: none;
}

.tokens__column-name {
	width: 100%;
	text-align: center;
	font-size: 12px;
	line-height: 14px;
	opacity: 0.4;
}

.tokens__column-group {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.tokens__column-value {
	font-size: 12px;
	line-height: 14px;
	opacity: 0.4;
}

.tokens__column-name:first-child {
	text-align: start;
	min-width: 150px;
}

.tokens__column-name:last-child {
	text-align: end;
	max-width: 100px;
}

.tokens__list {
	border-radius: 12px;
	overflow: hidden;
}

.skeleton-wrapper {
	padding: 0 12px;
	display: flex;
	flex-direction: column;
	gap: 3px 0;
	margin-bottom: 3px;
}

.table-skeleton {
	width: 100%;
	height: 45px;
}

.icon-wrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tokens__tooltip {
	bottom: 25px;
	left: 50%;
	transform: translateX(-49.5%);
}

.tokens__info-icon {
	opacity: 0.6;
	margin-left: 5px;
	width: 16px;
	height: 16px;
	cursor: help;
}

svg path {
	fill: var(--main-text-color);
}

@media screen and (max-width: 880px) {
	.tokens__list {
		display: flex;
		flex-direction: column;
		overflow: visible;
		gap: 8px 0;
	}

	.select-btn:hover {
		background: var(--iface-white8);
	}

	.select-btn .btn-icon {
		margin-right: 12px;
	}

	.tokens__column-list {
		display: none;
	}

	.tokens__controls {
		overflow-y: scroll;
	}

	.tokens__info {
		display: flex;
		align-items: center;
		margin-bottom: 6px;
	}

	.tokens__info-name {
		font-size: 13px;
		line-height: 16px;
		opacity: 0.4;
	}

	.tokens__icon {
		margin-right: 4px;
	}

	.dividing-line {
		margin: 0 8px;
		width: 1px;
		height: 10px;
		background: var(--iface-white14);
	}
}

</style>