import { THEME, toUserFriendlyAddress} from '@tonconnect/ui'
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'GET_PROOF_VERIFICATION',
            'GET_SLIPPAGE',
            'GET_EXPERT_MODE_VALUE',
            'GET_PRICE_IMPACT',
            'GET_MAX_POOL_VOLATILITY',
            'GET_MAX_INTERMEDIATE_TOKENS',
            'GET_MAX_SPLITS',
            'GET_THEME',
        ]),
        tonConnectSettings() {
            // let wallets = await this.tonConnectUi.getWallets()
            // let deWallet = wallets.find((item) => item?.name === 'DeWallet' || item?.appName === 'dewallet')
            return {
                manifestUrl: 'https://swap.coffee/tonconnect-manifest.json',
                uiPreferences: {
                    theme: THEME.DARK,
                },
            }
        },
    },
    methods: {
        ...mapActions([
            'SAVE_USER_SETTINGS'
        ]),
        // tonConnectUiOptions() {
        //     this.tonConnectUi.uiOptions = {
        //         twaReturnUrl: 'https://t.me/wmclick_bot'
        //     };
        // },
        restoreUiConnection() {
            this.tonConnectUi.connectionRestored
                .then(restored  => {
                    if (restored) {
                        console.log('connection restored')
                        let account = this.tonConnectUi.account
                        account.userFriendlyAddress = toUserFriendlyAddress(this.tonConnectUi.account.address)
                        account.imgUrl = this.tonConnectUi.walletInfo.imageUrl
                        let proof = JSON.parse(localStorage.getItem('tonProof_ver'))
                        if (proof) {
                            this.DEX_WALLET(account)
                            this.getContractVersion(account.userFriendlyAddress)
                            this.DEX_PROOF_VERIFICATION(proof)
                            this.getUserSettings()
                            this.bindReferralLink()
                        } else {
                            console.log('disconnect')
                            this.disconnectWallet()
                        }
                    } else {
                        console.log('Connection was not restored.');
                    }
                });
        },
        async disconnectWallet() {
            try {
                if (this.tonConnectUi.wallet !== null) {
                    await this.tonConnectUi.disconnect()
                    this.DEX_WALLET(null)
                }
                localStorage.removeItem('tonProof_ver')
            } catch(err) {
                console.error(err)
            }
        },
        tonproofSetConnect() {
            let storedVerification = JSON.parse(localStorage.getItem('tonProof_ver'))
            if (storedVerification) {
                return
            }

            this.tonConnectUi.setConnectRequestParameters({state: 'loading'});

            this.tokensApi.generateTonProofPayload()
                .then((data) => {
                    const payload = data.data;
                    
                    // this.DEX_PAYLOAD_ID(crypto.randomUUID());

                    this.tonConnectUi.setConnectRequestParameters({
                        state: "ready",
                        value: {tonProof: payload}
                    });

                })
        },
        generatePayload() {
            return crypto.randomUUID()
        },
        async getUserSettings() {
			try {
				let settings = await this.dexApiV2.readStorage(this.GET_DEX_WALLET?.address, this.GET_PROOF_VERIFICATION)
                if (settings.body?.dexSettings && settings.body?.globalSettings) {
                    this.SAVE_USER_SETTINGS(settings.body)
                } else {
                    await this.setDefaultSettings()
                }
			} catch(err) {
				console.error(err)
			}
		},
        async setDefaultSettings() {
            try {
                console.log('set default settings')
                const userCountryCode = await this.geoApi.getUserCountryCode();
                let newSettings;

                if (userCountryCode) {
                    this.$i18n.locale = this.getLocaleForCountry(userCountryCode);
                    newSettings = {
                        theme: this.GET_THEME || "dark",
                        lang: userCountryCode.toLowerCase(),
                    }
                    localStorage.setItem('globalSettings', JSON.stringify(newSettings))
                }

                let dex = {
                    slippage: this.GET_SLIPPAGE,
                    expertMode: this.GET_EXPERT_MODE_VALUE,
                    priceImpact: this.GET_PRICE_IMPACT,
                    maxPoolVolatility: this.GET_MAX_POOL_VOLATILITY,
                    maxIntermediateTokens: this.GET_MAX_INTERMEDIATE_TOKENS,
                    maxSplits: this.GET_MAX_SPLITS
                }

                await this.dexApiV2.writeStorage(this.GET_DEX_WALLET?.address, this.GET_PROOF_VERIFICATION, {
                    globalSettings: newSettings,  
                    dexSettings: dex
                })
            } catch (err) {
                console.error(err)
            }
        }
    }
}