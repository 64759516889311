<template>
	<div class="app-wrapper" :class="{'dex_wrapper': getRouteName === 'Dex'}">
		<AppHeader
			:tonConnectUi="tonConnectUi"
		/>
		<div class="app-wrapper__flex">
			<div class="sidebar">
				<Sidebar/>
			</div>
			<router-view
				:tonConnectUi="tonConnectUi"
				@updateWalletInfo="updateWalletInfo"
			/>
		</div>
		<AppFooter />
	</div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import Sidebar from "@/components/Sidebar.vue";
import {toUserFriendlyAddress} from "@tonconnect/ui";
import {pinnedTokens} from "@/helpers/dex/pinnedTokens";
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import tonConnectMixin from "@/mixins/tonConnectMixin";
import computedMixins from "@/mixins/computedMixins";
import AppFooter from "@/components/AppFooter.vue";

export default {
	name: 'AppWrapper',
	mixins: [computedMixins, tonConnectMixin],
	components: {
		AppFooter,
		Sidebar,
		AppHeader,
	},
	props: {
		tonConnectUi: {
			type: Object,
			default() {
				return {}
			}
		}
	},
	data() {
		return {
			unsubscribeConnect: null,
			loadInfoCount: 0,
			tonInfo: null,
			timeout: null,
		}
	},
	computed: {
		...mapGetters([
			'GET_TON_TOKENS',
			'GET_DEX_WALLET',
			'GET_PAYLOAD_ID',
			'GET_THEME'
		]),
	},
	methods: {
		...mapActions([
			'DEX_TON_TOKENS',
			'DEX_PINNED_TOKENS',
			'DEX_USER_TOKENS',
			'DEX_WALLET',
			'DEX_SEND_TOKEN',
			'DEX_RECEIVE_TOKEN',
			'DEX_SEND_AMOUNT',
			'DEX_RECEIVE_AMOUNT',
			'CLEAR_DEX_STORE',
			'DEX_WALLET_VERSION',
			'DEX_PAYLOAD_ID',
			'DEX_PROOF_VERIFICATION',
			'ROUTE_CHANGED'
		]),
		updateWalletInfo() {
			this.getAccountInfo(this.GET_DEX_WALLET)
		},
		async getPinnedTokens() {
			try {
				let result = pinnedTokens()
				this.DEX_PINNED_TOKENS(result)
			} catch (err) {
				console.error(err)
			}
		},
		async getTonTokens(retryCount = 0) {
			try {
				let res = await this.tokensApi.getTokenList()
				let tokens = []
				res.forEach((item) => {
					item.type = item.address === "0:0000000000000000000000000000000000000000000000000000000000000000" ? "native" : "jetton"
					if (item.address === "0:0000000000000000000000000000000000000000000000000000000000000000") {
						item.address = "native"
					}
					item.imported = false
					tokens.push(item)
				})

				let importedToken = JSON.parse(localStorage.getItem('importTokens'))
				if (importedToken) {
					importedToken.forEach((item) => {
						let findTokensItem = tokens.find((find) => find?.address === item?.address)
						if (findTokensItem) {
							return
						}
						tokens.push(item)
					})
				}

				this.DEX_TON_TOKENS(tokens)
				this.checkQueryParams(tokens)
				this.checkTwaParams(tokens)

				if (this.GET_DEX_WALLET !== null) {
					await this.getAccountInfo(this.GET_DEX_WALLET)
				}
			} catch (err) {
				console.error(err);
				if (retryCount < 20) {
					setTimeout(() => {
						this.getTonTokens(retryCount + 1);
					}, 5000);
				}
			}
		},
		// async setToncoin() {
		// 	try {
		// 		return {
		// 			"type": "native",
		// 			"address": "native",
		// 			"name": "Toncoin",
		// 			"symbol": "TON",
		// 			"image": "https://asset.ston.fi/img/EQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAM9c",
		// 			"decimals": 9,
		// 			"imported": false,
		// 			"price_usd": await this.getTonPrice(),
		// 			"change_price_24h": await this.getChangePrice()
		// 		}
		// 	} catch (err) {
		// 		console.error(err)
		// 	}
		// },
		async getTonPrice() {
			try {
				let res = await axios.get('https://tonapi.io/v2/rates?tokens=ton&currencies=usd')
				this.tonInfo = res?.data?.rates?.TON
				return res?.data?.rates?.TON?.prices?.USD
				// let res = await axios.get('https://api.dedust.io/v2/prices')
				// let find = res.data.find((item) => item.symbol === 'TON')
				// return find.price
			} catch (err) {
				console.log(err)
			}
		},
		async getChangePrice() {
			let res = await axios.get('https://tonapi.io/v2/rates?tokens=ton&currencies=usd')
			let tonDiff24 = res?.data?.rates?.TON?.diff_24h?.USD
			tonDiff24 = tonDiff24.trim();
			const wrongMinusChars = ['\u2010', '\u2011', '\u2012', '\u2013', '\u2014', '\u2015', '\u2212'];

			for (let wrongMinus of wrongMinusChars) {
				tonDiff24 = tonDiff24.replace(new RegExp(wrongMinus, 'g'), '-');
			}

			tonDiff24 = tonDiff24.replace(/[^0-9\.\-]/g, '');
			let num = parseFloat(tonDiff24);

			if (!isNaN(num)) {
				return num
			} else {
				return 0
			}
		},
		checkTwaParams(mergeTokens) {
			if (window.Telegram.WebApp.platform !== 'unknown') {
				let startParam = window.Telegram.WebApp?.initDataUnsafe?.start_param

				if (startParam) {
					// ft_{symbol}_st_{symbol}_fa_{amount}
					let params = startParam.split('_')
					if (params.length === 6) {
						let ft = params[1].toUpperCase()
						let st = params[3].toUpperCase()
						let fa = params[5]

						// console.log("params", ft, st, fa)

						let first = mergeTokens.find((item) => item.symbol === ft)
						let second = mergeTokens.find((item) => item.symbol === st)
						if (first) {
							this.DEX_SEND_TOKEN(first)
						}
						if (second) {
							this.DEX_RECEIVE_TOKEN(second)
						}
						if (fa) {
							this.DEX_SEND_AMOUNT(fa)
						}
					}
				}
			}
		},
		checkQueryParams(mergeTokens) {
			let route = this.$route
			if (route.query?.ref) {
				sessionStorage.setItem('referral_name', JSON.stringify(route.query?.ref))
			}
			if (route.query?.referral) {
				sessionStorage.setItem('user_referral', JSON.stringify(route.query?.referral))
			}
			if (route.query?.ft && route.query?.st) {
				let first = mergeTokens.find((item) => item.symbol === route.query?.ft)
				let second = mergeTokens.find((item) => item.symbol === route.query?.st)
				if (first) {
					this.DEX_SEND_TOKEN(first)
				}
				if (second) {
					this.DEX_RECEIVE_TOKEN(second)
				}
				setTimeout(() => {
					if (route.query?.fa > 0) {
						this.DEX_SEND_AMOUNT(Number(route.query?.fa))
					} else if (route.query?.sa > 0) {
						this.DEX_RECEIVE_AMOUNT(Number(route.query?.sa))
					}
				}, 10)
			} else if (route.query?.ft) {
				let first = mergeTokens.find((item) => item.symbol === route.query?.ft)
				if (first) {
					this.DEX_SEND_TOKEN(first)
				}
				setTimeout(() => {
					if (route.query?.fa > 0) {
						this.DEX_SEND_AMOUNT(Number(route.query?.fa))
					} else if (route.query?.sa > 0) {
						this.DEX_RECEIVE_AMOUNT(Number(route.query?.sa))
					}
				}, 10)
			} else { // Если нет параметров установить TON как SEND token
				let findToken = mergeTokens.find((item) => item.type === 'native')
				if (findToken) {
					this.DEX_SEND_TOKEN(findToken)
				}
			}
		},
		async getAccountInfo(wallet) {
			try {
				let balance = await this.getBalanceWithRetry(wallet)
				let walletInfo = {
					address: wallet.address,
					balance: Number(balance),
				}

				let mergedTokens = await this.mergeTonTokens(walletInfo)
				this.DEX_USER_TOKENS(mergedTokens)
			} catch (err) {
				console.log(err)
			}
		},
		async getBalanceWithRetry(wallet) {
			try {
				return await this.getBalance(wallet);
			} catch (err) {
				await new Promise(resolve => setTimeout(resolve, 2000));
				return await this.getBalanceFromTonApi(wallet);
			}
		},
		async getBalance(wallet) {
			try {
				return await this.dexApiV2.getBalance(wallet.address)
			} catch(err) {
				throw err
			}
		},
		async getBalanceFromTonApi(wallet) {
			try {
				let res = await this.tonApi.getTonWalletInfo(wallet.address);
				return res?.balance
			} catch (err) {
				throw err;
			}
		},
		async mergeTonTokens(walletInfo) {
			let jettons = await this.getTonJettons(walletInfo)
			let toncoin = this.GET_TON_TOKENS.find((item) => item.address === 'native')
			if (walletInfo?.balance) {
				toncoin.balance = walletInfo?.balance / Math.pow(10, toncoin?.decimals)
			}
			if (jettons.length === 0) {
				jettons.unshift(toncoin)
			} else if (jettons.length > 0 && !jettons.find((item) => item.name === toncoin.name)) {
				jettons.unshift(toncoin)
			}
			return jettons
		},
		async getTonJettons(wallet) {
			try {
				let array = []
				let tokensWithBalance = this.GET_TON_TOKENS
				let jettons = await this.tonApi.getTonJettons(toUserFriendlyAddress(wallet.address))

				jettons?.balances.forEach((item) => {
					let findItem = this.GET_TON_TOKENS.find((find) => item.jetton.address === find.address)
					if (findItem) {
						findItem.balance = item?.balance / Math.pow(10, findItem?.decimals)
						array.push(findItem)
					}
				})

				tokensWithBalance.forEach((token) => {
					let findItem = array.find((find) => find.name === token.name)
					if (findItem) {
						token = findItem
					} else {
						token.balance = 0
					}
				})

				this.DEX_TON_TOKENS(tokensWithBalance)
				return array
			} catch (err) {
				// sleep 1 seconds and retry
				await new Promise((resolve) => setTimeout(resolve, 1000))
				return await this.getTonJettons(wallet)
			}
		},
		changeMetaTheme() {
			let meta = document.querySelector('meta[name="theme-color"]')
			if (meta) {
				if (this.GET_THEME === 'coffee') {
					meta.setAttribute('content', '#0A0706')
					return
				}
				if (this.GET_THEME === 'light') {
					meta.setAttribute('content', '#f8f8f8')
				}
				if (this.GET_THEME === 'dark') {
					meta.setAttribute('content', '#0A0A0A')
				}
			}
		}
	},
	mounted() {
		this.getPinnedTokens()
		let tonConnectStorage = JSON.parse(localStorage.getItem('ton-connect-storage_bridge-connection'))
		let walletInfoStorage = JSON.parse(localStorage.getItem('ton-connect-ui_wallet-info'))
		let wallet = tonConnectStorage?.connectEvent?.payload?.items[0]
		let proof = JSON.parse(localStorage.getItem('tonProof_ver'))
		if (wallet) {
			wallet.userFriendlyAddress = toUserFriendlyAddress(wallet?.address)
			wallet.imgUrl = walletInfoStorage?.imageUrl
			if (tonConnectStorage) {
				this.DEX_WALLET(wallet)
			}
		}
		if (proof) {
			this.DEX_PROOF_VERIFICATION(proof)
		}
		// this.subscribeConnect()
		// this.restoreUiConnection()
		//
		// this.tonproofSetConnect()

		setTimeout(() => {
			if (this.GET_DEX_WALLET === null) {
				this.getTonTokens()
			}
		}, 1000)

	},
	unmounted() {
		if (this.unsubscribeConnect !== null) {
			this.unsubscribeConnect()
		}
		this.CLEAR_DEX_STORE()
	},
	watch: {
		GET_DEX_WALLET: {
			handler() {
				let tonConnectStorage = JSON.parse(localStorage.getItem('ton-connect-storage_bridge-connection'))
				if (this.GET_DEX_WALLET !== null) {
					if (this.loadInfoCount === 0) {
						this.getTonTokens()
					}
					if (tonConnectStorage) {
						this.loadInfoCount++
					}
					// this.loadInfoCount++
				} else {
					this.loadInfoCount = 0
				}
			}
		},
		GET_THEME: {
			handler() {
				this.changeMetaTheme()
			}
		},
		getRouteName: {
			handler() {
				clearTimeout(this.timeout)
				this.ROUTE_CHANGED(true)
				this.timeout = setTimeout(() => {
					this.ROUTE_CHANGED(false)
				}, 300)
			}
		}
	}
}
</script>

<style scoped>

.app-wrapper {
	margin-top: 68px;
	overflow: hidden;
	background: var(--main-bg-color);
	height: 100%;
	min-height: calc(100dvh - 68px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.app-wrapper__flex {
	width: calc(100% + 240px);
	display: flex;
	//overflow: auto;
}

@media screen and (max-width: 1180px) {
	.app-wrapper {
		max-height: 100%;
		overflow: auto;
	}

	.app-wrapper__flex {
		width: 100%;
	}

	.sidebar {
		display: none;
	}
}

@media screen and (max-width: 880px) {
	.app-wrapper {
		margin-top: 64px;
		min-height: calc(100dvh - 64px);
	}

	.dex_wrapper {
		display: block;
	}
}
</style>