export default {
	state: () => ({
		isVisible: false,
		routeChanged: false
	}),
	mutations: {
		SET_CHART_VISIBLE(state, value) {
			state.isVisible = value;
		},
		SET_ROUTE_CHANGED(state, value) {
			state.routeChanged = value;
		},
	},
	actions: {
		TOGGLE_CHART_VISIBLE({commit}, item) {
			commit('SET_CHART_VISIBLE', item)
		},
		ROUTE_CHANGED({commit}, item) {
			commit('SET_ROUTE_CHANGED', item)
		},
	},
	getters: {
		GET_CHART_VISIBLE: state => {
			return state.isVisible;
		},
		GET_ROUTE_CHANGED: state => {
			return state.routeChanged
		}
	}

}