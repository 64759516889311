<template>
	<div :class="['tokens__item', { 'item__active': selected }]"
		 @click="selectToken"
		 @mouseenter="showCheckbox = true"
		 @mouseleave="showCheckbox = false"
	>
		<div class="item__content">
			<div class="item__token-info">
				<transition name="slide">
					<div :class="['item__checkbox', {'checkbox__active': selected}]"
						 v-show="showCheckbox === true || selected"
					>
						<div class="checkbox__checked" v-if="selected">
							<img class="checked__img" src="@/assets/claim/checkbox-select.svg" alt="checked">
						</div>
					</div>
				</transition>
				<img :src="item?.image" alt="" class="item__image">
				<p class="item__token-name">{{ item?.symbol }}</p>
				<a target="_blank" class="item__link"
				   :href="`https://tonviewer.com/${item?.address}`"
				   v-if="item?.address !== 'native'"
				>
					<svg class="item__link-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
						<g>
							<path d="M13.3333 7.33333C13.1565 7.33333 12.987 7.40357 12.8619 7.5286C12.7369 7.65362 12.6667 7.82319 12.6667 8V12C12.6667 12.1768 12.5964 12.3464 12.4714 12.4714C12.3464 12.5964 12.1768 12.6667 12 12.6667H4C3.82319 12.6667 3.65362 12.5964 3.5286 12.4714C3.40357 12.3464 3.33333 12.1768 3.33333 12V4C3.33333 3.82319 3.40357 3.65362 3.5286 3.5286C3.65362 3.40357 3.82319 3.33333 4 3.33333H8C8.17681 3.33333 8.34638 3.2631 8.4714 3.13807C8.59643 3.01305 8.66667 2.84348 8.66667 2.66667C8.66667 2.48986 8.59643 2.32029 8.4714 2.19526C8.34638 2.07024 8.17681 2 8 2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V12C2 12.5304 2.21071 13.0391 2.58579 13.4142C2.96086 13.7893 3.46957 14 4 14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12V8C14 7.82319 13.9298 7.65362 13.8047 7.5286C13.6797 7.40357 13.5101 7.33333 13.3333 7.33333Z" fill="white"/>
							<path d="M10.6668 3.33333H11.7201L7.52679 7.52C7.4643 7.58198 7.41471 7.65571 7.38086 7.73695C7.34702 7.81819 7.32959 7.90533 7.32959 7.99333C7.32959 8.08134 7.34702 8.16848 7.38086 8.24972C7.41471 8.33096 7.4643 8.40469 7.52679 8.46667C7.58876 8.52915 7.6625 8.57875 7.74374 8.61259C7.82498 8.64644 7.91211 8.66387 8.00012 8.66387C8.08813 8.66387 8.17527 8.64644 8.25651 8.61259C8.33775 8.57875 8.41148 8.52915 8.47346 8.46667L12.6668 4.28V5.33333C12.6668 5.51014 12.737 5.67971 12.8621 5.80474C12.9871 5.92976 13.1566 6 13.3335 6C13.5103 6 13.6798 5.92976 13.8049 5.80474C13.9299 5.67971 14.0001 5.51014 14.0001 5.33333V2.66667C14.0001 2.48986 13.9299 2.32029 13.8049 2.19526C13.6798 2.07024 13.5103 2 13.3335 2H10.6668C10.49 2 10.3204 2.07024 10.1954 2.19526C10.0704 2.32029 10.0001 2.48986 10.0001 2.66667C10.0001 2.84348 10.0704 3.01305 10.1954 3.13807C10.3204 3.2631 10.49 3.33333 10.6668 3.33333Z" fill="white"/>
						</g>
					</svg>
				</a>
			</div>
			<div class="item__group">
				<p class="item__value">{{ getItemValue }}</p>
			</div>
			<div class="item__group">
				<p class="item__value">{{ displayTokenAmount }}</p>
			</div>
			<div class="item__group"
				 v-if="item?.price_usd !== null"
			>
				<svg class="item__icon" :class="item?.price_change_24h > 0 ? 'up_icon' : 'down_icon'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
					<path d="M11.7792 8.91732C11.7461 8.99727 11.6901 9.06561 11.6181 9.1137C11.5462 9.16178 11.4616 9.18746 11.3751 9.18748H2.6251C2.53852 9.18755 2.45387 9.16192 2.38186 9.11386C2.30985 9.06579 2.25372 8.99743 2.22058 8.91745C2.18744 8.83746 2.17877 8.74944 2.19568 8.66453C2.21259 8.57962 2.25431 8.50163 2.31557 8.44045L6.69057 4.06545C6.7312 4.02477 6.77945 3.9925 6.83256 3.97048C6.88568 3.94847 6.94261 3.93713 7.0001 3.93713C7.05759 3.93713 7.11452 3.94847 7.16764 3.97048C7.22075 3.9925 7.269 4.02477 7.30963 4.06545L11.6846 8.44045C11.7458 8.50167 11.7874 8.57965 11.8043 8.66453C11.8211 8.74942 11.8124 8.83739 11.7792 8.91732Z" fill="#55FF85"/>
				</svg>
				<p class="item__value" :class="item?.price_change_24h > 0 ? 'up_price' : 'down_price'">
					{{ '$' + item?.price_usd.toFixed(2) }}
				</p>
			</div>
			<div class="item__group">
<!--				<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">-->
<!--					<g opacity="0.8">-->
<!--						<path d="M14.4324 13.25C13.4805 11.6044 12.0137 10.4244 10.3018 9.86499C11.1486 9.3609 11.8065 8.59279 12.1744 7.67861C12.5424 6.76444 12.6001 5.75474 12.3387 4.80459C12.0773 3.85444 11.5112 3.01636 10.7274 2.41907C9.94359 1.82178 8.98538 1.49829 7.99993 1.49829C7.01447 1.49829 6.05626 1.82178 5.27244 2.41907C4.48863 3.01636 3.92255 3.85444 3.66114 4.80459C3.39973 5.75474 3.45744 6.76444 3.82542 7.67861C4.19339 8.59279 4.85128 9.3609 5.69805 9.86499C3.98618 10.4237 2.5193 11.6037 1.56743 13.25C1.53252 13.3069 1.50936 13.3702 1.49933 13.4363C1.4893 13.5023 1.49259 13.5696 1.509 13.6343C1.52542 13.6991 1.55463 13.7598 1.59492 13.8131C1.63521 13.8663 1.68575 13.911 1.74357 13.9444C1.80138 13.9778 1.8653 13.9992 1.93155 14.0075C1.99781 14.0158 2.06505 14.0108 2.12931 13.9926C2.19358 13.9745 2.25356 13.9437 2.30573 13.902C2.3579 13.8604 2.40119 13.8087 2.43305 13.75C3.61055 11.715 5.6918 10.5 7.99993 10.5C10.3081 10.5 12.3893 11.715 13.5668 13.75C13.5987 13.8087 13.642 13.8604 13.6941 13.902C13.7463 13.9437 13.8063 13.9745 13.8705 13.9926C13.9348 14.0108 14.002 14.0158 14.0683 14.0075C14.1345 13.9992 14.1985 13.9778 14.2563 13.9444C14.3141 13.911 14.3646 13.8663 14.4049 13.8131C14.4452 13.7598 14.4744 13.6991 14.4908 13.6343C14.5073 13.5696 14.5106 13.5023 14.5005 13.4363C14.4905 13.3702 14.4673 13.3069 14.4324 13.25ZM4.49993 5.99999C4.49993 5.30776 4.7052 4.63107 5.08978 4.0555C5.47437 3.47992 6.02099 3.03132 6.66053 2.76641C7.30007 2.50151 8.00381 2.43219 8.68274 2.56724C9.36168 2.70229 9.98531 3.03563 10.4748 3.52512C10.9643 4.0146 11.2976 4.63824 11.4327 5.31718C11.5677 5.99611 11.4984 6.69984 11.2335 7.33938C10.9686 7.97893 10.52 8.52555 9.94442 8.91014C9.36885 9.29472 8.69216 9.49999 7.99993 9.49999C7.07197 9.499 6.18231 9.12993 5.52615 8.47377C4.86999 7.81761 4.50092 6.92795 4.49993 5.99999Z" fill="white"/>-->
<!--					</g>-->
<!--				</svg>-->
				<p class="item__value">{{ getTrimWallet }}</p>
			</div>
<!--			<div class="item__group"-->
<!--				 :class="{'hidden_value': item?.address === 'native'}"-->
<!--				 v-if="item.holders_count !== null"-->
<!--			>-->
<!--				<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">-->
<!--					<g opacity="0.8">-->
<!--						<path d="M14.4324 13.25C13.4805 11.6044 12.0137 10.4244 10.3018 9.86499C11.1486 9.3609 11.8065 8.59279 12.1744 7.67861C12.5424 6.76444 12.6001 5.75474 12.3387 4.80459C12.0773 3.85444 11.5112 3.01636 10.7274 2.41907C9.94359 1.82178 8.98538 1.49829 7.99993 1.49829C7.01447 1.49829 6.05626 1.82178 5.27244 2.41907C4.48863 3.01636 3.92255 3.85444 3.66114 4.80459C3.39973 5.75474 3.45744 6.76444 3.82542 7.67861C4.19339 8.59279 4.85128 9.3609 5.69805 9.86499C3.98618 10.4237 2.5193 11.6037 1.56743 13.25C1.53252 13.3069 1.50936 13.3702 1.49933 13.4363C1.4893 13.5023 1.49259 13.5696 1.509 13.6343C1.52542 13.6991 1.55463 13.7598 1.59492 13.8131C1.63521 13.8663 1.68575 13.911 1.74357 13.9444C1.80138 13.9778 1.8653 13.9992 1.93155 14.0075C1.99781 14.0158 2.06505 14.0108 2.12931 13.9926C2.19358 13.9745 2.25356 13.9437 2.30573 13.902C2.3579 13.8604 2.40119 13.8087 2.43305 13.75C3.61055 11.715 5.6918 10.5 7.99993 10.5C10.3081 10.5 12.3893 11.715 13.5668 13.75C13.5987 13.8087 13.642 13.8604 13.6941 13.902C13.7463 13.9437 13.8063 13.9745 13.8705 13.9926C13.9348 14.0108 14.002 14.0158 14.0683 14.0075C14.1345 13.9992 14.1985 13.9778 14.2563 13.9444C14.3141 13.911 14.3646 13.8663 14.4049 13.8131C14.4452 13.7598 14.4744 13.6991 14.4908 13.6343C14.5073 13.5696 14.5106 13.5023 14.5005 13.4363C14.4905 13.3702 14.4673 13.3069 14.4324 13.25ZM4.49993 5.99999C4.49993 5.30776 4.7052 4.63107 5.08978 4.0555C5.47437 3.47992 6.02099 3.03132 6.66053 2.76641C7.30007 2.50151 8.00381 2.43219 8.68274 2.56724C9.36168 2.70229 9.98531 3.03563 10.4748 3.52512C10.9643 4.0146 11.2976 4.63824 11.4327 5.31718C11.5677 5.99611 11.4984 6.69984 11.2335 7.33938C10.9686 7.97893 10.52 8.52555 9.94442 8.91014C9.36885 9.29472 8.69216 9.49999 7.99993 9.49999C7.07197 9.499 6.18231 9.12993 5.52615 8.47377C4.86999 7.81761 4.50092 6.92795 4.49993 5.99999Z" fill="white"/>-->
<!--					</g>-->
<!--				</svg>-->
<!--				<p class="item__value">{{ item?.address !== 'native' ? getTokenHolders : 0 }}</p>-->
<!--			</div>-->
			<div class="item__group"
				 :class="{'hidden_value': item?.address === 'native'}"
				 v-if="item.tvl !== null"
			>
				<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
					<path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd" d="M3.99987 3.85181C3.85312 3.85181 3.76167 4.01718 3.83562 4.14887L7.4296 10.5487V3.85181H3.99987ZM8.57057 3.85181V10.5487L12.1646 4.14887C12.2385 4.01718 12.1471 3.85181 12.0003 3.85181H8.57057ZM2.85008 4.74605C2.33243 3.82426 2.97252 2.66663 3.99987 2.66663H12.0003C13.0276 2.66663 13.6677 3.82426 13.1501 4.74605L8.49285 13.0393C8.39062 13.2214 8.20299 13.3333 8.00009 13.3333C7.79718 13.3333 7.60955 13.2214 7.50731 13.0393L2.85008 4.74605Z" fill="white"/>
				</svg>
				<p class="item__value">{{ item?.address !== 'native' ? getTokenTvl : 0 }}</p>
			</div>
		</div>
		<div class="item__content_mob">
			<div class="item__token-info">
				<div :class="['item__checkbox', {'checkbox__active': selected}]">
					<div class="checkbox__checked" v-if="selected">
						<img class="checked__img" src="@/assets/claim/checkbox-select.svg" alt="checked">
					</div>
				</div>
				<img :src="item?.image" alt="" class="item__image">
				<div class="item__flex-column">
					<div class="item__flex-group">
						<p class="item__token-name">{{ item?.symbol }}</p>
						<a class="item__link" target="_blank"
						   :href="`https://tonviewer.com/${item?.address}`"
						   v-if="item?.address !== 'native'"
						>
							<svg class="item__link-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<g>
									<path d="M13.3333 7.33333C13.1565 7.33333 12.987 7.40357 12.8619 7.5286C12.7369 7.65362 12.6667 7.82319 12.6667 8V12C12.6667 12.1768 12.5964 12.3464 12.4714 12.4714C12.3464 12.5964 12.1768 12.6667 12 12.6667H4C3.82319 12.6667 3.65362 12.5964 3.5286 12.4714C3.40357 12.3464 3.33333 12.1768 3.33333 12V4C3.33333 3.82319 3.40357 3.65362 3.5286 3.5286C3.65362 3.40357 3.82319 3.33333 4 3.33333H8C8.17681 3.33333 8.34638 3.2631 8.4714 3.13807C8.59643 3.01305 8.66667 2.84348 8.66667 2.66667C8.66667 2.48986 8.59643 2.32029 8.4714 2.19526C8.34638 2.07024 8.17681 2 8 2H4C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V12C2 12.5304 2.21071 13.0391 2.58579 13.4142C2.96086 13.7893 3.46957 14 4 14H12C12.5304 14 13.0391 13.7893 13.4142 13.4142C13.7893 13.0391 14 12.5304 14 12V8C14 7.82319 13.9298 7.65362 13.8047 7.5286C13.6797 7.40357 13.5101 7.33333 13.3333 7.33333Z" fill="white"/>
									<path d="M10.6668 3.33333H11.7201L7.52679 7.52C7.4643 7.58198 7.41471 7.65571 7.38086 7.73695C7.34702 7.81819 7.32959 7.90533 7.32959 7.99333C7.32959 8.08134 7.34702 8.16848 7.38086 8.24972C7.41471 8.33096 7.4643 8.40469 7.52679 8.46667C7.58876 8.52915 7.6625 8.57875 7.74374 8.61259C7.82498 8.64644 7.91211 8.66387 8.00012 8.66387C8.08813 8.66387 8.17527 8.64644 8.25651 8.61259C8.33775 8.57875 8.41148 8.52915 8.47346 8.46667L12.6668 4.28V5.33333C12.6668 5.51014 12.737 5.67971 12.8621 5.80474C12.9871 5.92976 13.1566 6 13.3335 6C13.5103 6 13.6798 5.92976 13.8049 5.80474C13.9299 5.67971 14.0001 5.51014 14.0001 5.33333V2.66667C14.0001 2.48986 13.9299 2.32029 13.8049 2.19526C13.6798 2.07024 13.5103 2 13.3335 2H10.6668C10.49 2 10.3204 2.07024 10.1954 2.19526C10.0704 2.32029 10.0001 2.48986 10.0001 2.66667C10.0001 2.84348 10.0704 3.01305 10.1954 3.13807C10.3204 3.2631 10.49 3.33333 10.6668 3.33333Z" fill="white"/>
								</g>
							</svg>
						</a>
						<div class="dividing-line mob-hidden"></div>
						<p class="item__value mob-hidden">{{ getTrimWallet }}</p>
					</div>
					<div class="item__flex-group token-stats"
						 v-if="item.price_usd !== null && item.tvl !== null && item.holders_count !== null"
					>
						<div class="item__group">
							<svg class="item__icon" :class="item?.price_change_24h > 0 ? 'up_icon' : 'down_icon'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
								<path d="M11.7792 8.91732C11.7461 8.99727 11.6901 9.06561 11.6181 9.1137C11.5462 9.16178 11.4616 9.18746 11.3751 9.18748H2.6251C2.53852 9.18755 2.45387 9.16192 2.38186 9.11386C2.30985 9.06579 2.25372 8.99743 2.22058 8.91745C2.18744 8.83746 2.17877 8.74944 2.19568 8.66453C2.21259 8.57962 2.25431 8.50163 2.31557 8.44045L6.69057 4.06545C6.7312 4.02477 6.77945 3.9925 6.83256 3.97048C6.88568 3.94847 6.94261 3.93713 7.0001 3.93713C7.05759 3.93713 7.11452 3.94847 7.16764 3.97048C7.22075 3.9925 7.269 4.02477 7.30963 4.06545L11.6846 8.44045C11.7458 8.50167 11.7874 8.57965 11.8043 8.66453C11.8211 8.74942 11.8124 8.83739 11.7792 8.91732Z" fill="#55FF85"/>
							</svg>
							<p class="item__value" :class="item?.price_change_24h > 0 ? 'up_price' : 'down_price'">
								{{ '$' + item?.price_usd.toFixed(2) }}</p>
						</div>
						<div class="dividing-line" :class="{'hidden_value': item?.address === 'native'}"></div>
						<div class="item__group"
							 :class="{'hidden_value': item?.address === 'native'}"
						>
							<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<g opacity="0.8">
									<path d="M14.4324 13.25C13.4805 11.6044 12.0137 10.4244 10.3018 9.86499C11.1486 9.3609 11.8065 8.59279 12.1744 7.67861C12.5424 6.76444 12.6001 5.75474 12.3387 4.80459C12.0773 3.85444 11.5112 3.01636 10.7274 2.41907C9.94359 1.82178 8.98538 1.49829 7.99993 1.49829C7.01447 1.49829 6.05626 1.82178 5.27244 2.41907C4.48863 3.01636 3.92255 3.85444 3.66114 4.80459C3.39973 5.75474 3.45744 6.76444 3.82542 7.67861C4.19339 8.59279 4.85128 9.3609 5.69805 9.86499C3.98618 10.4237 2.5193 11.6037 1.56743 13.25C1.53252 13.3069 1.50936 13.3702 1.49933 13.4363C1.4893 13.5023 1.49259 13.5696 1.509 13.6343C1.52542 13.6991 1.55463 13.7598 1.59492 13.8131C1.63521 13.8663 1.68575 13.911 1.74357 13.9444C1.80138 13.9778 1.8653 13.9992 1.93155 14.0075C1.99781 14.0158 2.06505 14.0108 2.12931 13.9926C2.19358 13.9745 2.25356 13.9437 2.30573 13.902C2.3579 13.8604 2.40119 13.8087 2.43305 13.75C3.61055 11.715 5.6918 10.5 7.99993 10.5C10.3081 10.5 12.3893 11.715 13.5668 13.75C13.5987 13.8087 13.642 13.8604 13.6941 13.902C13.7463 13.9437 13.8063 13.9745 13.8705 13.9926C13.9348 14.0108 14.002 14.0158 14.0683 14.0075C14.1345 13.9992 14.1985 13.9778 14.2563 13.9444C14.3141 13.911 14.3646 13.8663 14.4049 13.8131C14.4452 13.7598 14.4744 13.6991 14.4908 13.6343C14.5073 13.5696 14.5106 13.5023 14.5005 13.4363C14.4905 13.3702 14.4673 13.3069 14.4324 13.25ZM4.49993 5.99999C4.49993 5.30776 4.7052 4.63107 5.08978 4.0555C5.47437 3.47992 6.02099 3.03132 6.66053 2.76641C7.30007 2.50151 8.00381 2.43219 8.68274 2.56724C9.36168 2.70229 9.98531 3.03563 10.4748 3.52512C10.9643 4.0146 11.2976 4.63824 11.4327 5.31718C11.5677 5.99611 11.4984 6.69984 11.2335 7.33938C10.9686 7.97893 10.52 8.52555 9.94442 8.91014C9.36885 9.29472 8.69216 9.49999 7.99993 9.49999C7.07197 9.499 6.18231 9.12993 5.52615 8.47377C4.86999 7.81761 4.50092 6.92795 4.49993 5.99999Z" fill="white"/>
								</g>
							</svg>
							<p class="item__value">
								{{ item?.address !== 'native' ? getTokenHolders : 0 }}
							</p>
						</div>
						<div class="dividing-line" :class="{'hidden_value': item?.address === 'native'}"></div>
						<div class="item__group"
							 :class="{'hidden_value': item?.address === 'native'}"
						>
							<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
								<path opacity="0.8" fill-rule="evenodd" clip-rule="evenodd" d="M3.99987 3.85181C3.85312 3.85181 3.76167 4.01718 3.83562 4.14887L7.4296 10.5487V3.85181H3.99987ZM8.57057 3.85181V10.5487L12.1646 4.14887C12.2385 4.01718 12.1471 3.85181 12.0003 3.85181H8.57057ZM2.85008 4.74605C2.33243 3.82426 2.97252 2.66663 3.99987 2.66663H12.0003C13.0276 2.66663 13.6677 3.82426 13.1501 4.74605L8.49285 13.0393C8.39062 13.2214 8.20299 13.3333 8.00009 13.3333C7.79718 13.3333 7.60955 13.2214 7.50731 13.0393L2.85008 4.74605Z" fill="white"/>
							</svg>
							<p class="item__value">
								{{ item?.address !== 'native' ? getTokenTvl : 0 }}
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="item__flex-column">
				<div class="item__group">
					<p class="item__value amount-value">{{ displayTokenAmount }}</p>
				</div>
				<div class="item__group">
					<p class="item__value">{{ getItemValue }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import methodsMixins from "@/mixins/methodsMixins";
import computedMixins from "@/mixins/computedMixins";
import {mapGetters} from "vuex";
import TooltipWrapper from "@/components/ui/TooltipWrapper.vue";

export default {
	name: "ClaimTokenItem",
	components: {TooltipWrapper},
	mixins: [methodsMixins, computedMixins],
	props: {
		item: {
			type: Object,
			default() {
				return {}
			},
			required: true
		},
		selected: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			showCheckbox: false,
		}
	},
	computed: {
		...mapGetters([
			'GET_TON_TOKENS',
		]),
		getTrimWallet() {
			let address = this.item?.wallet_address
			if (address) {
				let stringLength = 4
				let firstHalf = address.substring(0, stringLength);
				let secondHalf = address.substring(address.length - stringLength);
				return firstHalf + '...' + secondHalf
			} else {
				return ''
			}
		},
		removeNano() {
			return Number(this.item?.amount) / Math.pow(10, this.item?.decimals)
		},
		displayTokenAmount() {
			if (Number.isInteger(this.removeNano)) {
				return this.removeNano
			} else {
				if (this.removeNano > 1) {
					return this.removeNano.toFixed(2)
				}
				return this.removeNano.toFixed(6)
			}
		},
		getItemValue() {
			if (Number.isInteger(this.item?.value)) {
				return '$' + `${this.item?.value}`
			} else {
				if (this.item?.value > 1) {
					return '$' + this.item?.value.toFixed(2)
				}
				return '$' + this.item?.value.toFixed(6)
			}
			// let value = this.item?.price_usd * this.removeNano
			// if (Number.isInteger(value)) {
			// 	return '$' + value
			// } else {
			// 	if (value > 1) {
			// 		return '$' + value.toFixed(2)
			// 	}
			// 	return '$' + value.toFixed(6)
			// }
		},
		getTokenTvl() {
			let tonPrice = (this.GET_TON_TOKENS.find((item) => item.address === 'native')).price_usd
			if (this.item?.tvl) {
				let tvlInTon = this.item?.tvl / tonPrice
				let reduceNum = this.filterBalance(tvlInTon)
				if (typeof reduceNum === 'number') {
					if (Number.isInteger(reduceNum)) {
						return reduceNum
					} else {
						return reduceNum.toFixed(1)
					}
				} else if (typeof reduceNum === 'object') {
					return reduceNum.result + reduceNum.unit
				} else {
					return 0
				}
			} else {
				return 0
			}
		},
		getTokenHolders() {
			if (this.item?.holders_count) {
				let reduceNum = this.filterBalance(this.item?.holders_count)
				if (typeof reduceNum === 'number') {
					if (Number.isInteger(reduceNum)) {
						return reduceNum
					} else {
						return reduceNum.toFixed(1)
					}
				} else if (typeof reduceNum === 'object') {
					return reduceNum.result + reduceNum.unit
				} else {
					return 0
				}
			} else {
				return 0
			}
		},
	},
	methods: {
		selectToken() {
			this.$emit('select', this.item);
		}
	}
}
</script>


<style scoped>

.slide-enter-active, .slide-leave-active {
	transition: .2s ease-out;
}

.slide-enter-from, .slide-leave-to {
	margin-left: -30px;
}

.item__checkbox {
	margin-right: 8px;
	min-width: 16px;
	width: 16px;
	height: 16px;
	border-radius: 6px;
	border: 1px solid var(--iface-white20);
	background: var(--iface-main-bg);
}

.item__checkbox:hover {
	border: 1px solid var(--iface-white24);
}

.checkbox__checked {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	//margin-left: 1px;
}

.checkbox__active {
	border: none;
	background: var(--iface-accent-color);
}

.checkbox__active:hover {
	background: var(--iface-accent-hover);
	border: none;
}

.checked__img {
	width: 14px;
	height: 14px;
}

.tokens__item {
	cursor: pointer;
	padding: 12px 14px;
	gap: 0 10px;
	background: var(--iface-white4);
	transition: .2s background;
	border-bottom: 1px solid transparent;
}

.tokens__item:hover {
	background: var(--iface-white8);
}

.tokens__item.item__active:nth-child(even) {
	background: var(--iface-white12);
}

.tokens__item:hover:nth-child(even) {
	background: var(--iface-white8);
}

.tokens__item:nth-child(even) {
	background: var(--iface-white2);
}


.item__content {
	display: flex;
	align-items: center;
}

.item__content_mob {
	display: none;
}

.item__image {
	border-radius: 100px;
	width: 24px;
	height: 24px;
}

.item__active {
	background: var(--iface-white12);
	border-bottom: 1px solid #181413;
}

.item__token-info {
	min-width: 150px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 0 8px;
}

.item__group {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.item__group:last-child {
	max-width: 100px;
	justify-content: flex-end;
}

.item__token-name {
	font-size: 14px;
	line-height: 16px;
	font-weight: 500;
}

.item__icon {
	margin-right: 4px;
}

.item__link {
	display: flex;
	justify-content: center;
	align-items: center;
}

.item__link-icon {
	opacity: 0.6;
	transition: 0.2s;
}

.item__link:hover .item__link-icon {
	opacity: 1;
}

svg path {
	fill: var(--main-text-color);
}

.up_icon path {
	fill: var(--main-green);
}

.down_icon {
	transform: rotate(180deg);
}

.down_icon path {
	fill: var(--main-red);
}

.item__value {
	font-size: 14px;
}

.up_price {
	color: var(--main-green);
}

.down_price {
	color: var(--main-red);
}

.hidden_value {
	opacity: 0;
}

@media screen and (max-width: 880px) {
	.tokens__item {
		padding: 10px;
		border-radius: 12px;
	}

	.item__checkbox {
		margin-right: 0;
	}

	.tokens__item:nth-child(even) {
		background: var(--iface-white4);
	}

	.item__content {
		display: none;
	}

	.item__content_mob {
		display: flex;
		align-items: center;
	}

	.item__flex-column {
		display: flex;
		flex-direction: column;
		gap: 6px 0;
	}

	.item__flex-group {
		display: flex;
		align-items: center;
		gap: 0 6px;
	}

	.item__image {
		width: 30px;
		height: 30px;
	}

	.item__icon {
		width: 14px;
		height: 14px;
	}

	.item__value {
		font-size: 12px;
		line-height: 14px;
	}

	.amount-value {
		font-size: 14px;
		line-height: 16px;
	}

	.item__group {
		justify-content: flex-end;
	}

	.item__group:last-child {
		max-width: 100%;
	}

	.dividing-line {
		width: 1px;
		height: 10px;
		margin: 0 2px;
		background: var(--iface-white14);
	}
}

@media screen and (max-width: 380px) {
	.amount-value {
		font-size: 13px;
		line-height: 15px;
	}
}

@media screen and (max-width: 365px) {
	.token-stats {
		display: none;
	}
}

@media screen and (max-width: 350px) {
	.mob-hidden {
		display: none;
	}
}
</style>