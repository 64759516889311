<template>
	<footer class="footer" :class="{'dex_footer': getRouteName === 'Dex'}">
		<nav class="footer__nav">
			<ul class="footer__list">
<!--				<li class="footer__item item">-->
<!--					<a href="" class="item__link" target="_blank">-->
<!--						<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
<!--							<path d="M10 2C8.41775 2 6.87103 2.46919 5.55544 3.34824C4.23985 4.22729 3.21447 5.47672 2.60897 6.93853C2.00347 8.40034 1.84504 10.0089 2.15372 11.5607C2.4624 13.1126 3.22433 14.538 4.34315 15.6569C5.46197 16.7757 6.88743 17.5376 8.43928 17.8463C9.99113 18.155 11.5997 17.9965 13.0615 17.391C14.5233 16.7855 15.7727 15.7602 16.6518 14.4446C17.5308 13.129 18 11.5822 18 10C17.9978 7.87896 17.1542 5.84543 15.6544 4.34562C14.1546 2.84581 12.121 2.00224 10 2ZM7.97154 13.0769H12.0285C11.6154 14.4877 10.9231 15.7592 10 16.7608C9.07692 15.7592 8.38462 14.4877 7.97154 13.0769ZM7.69231 11.8462C7.48821 10.6238 7.48821 9.37615 7.69231 8.15385H12.3077C12.5118 9.37615 12.5118 10.6238 12.3077 11.8462H7.69231ZM3.23077 10C3.23024 9.37572 3.31645 8.7544 3.48693 8.15385H6.44539C6.2618 9.37777 6.2618 10.6222 6.44539 11.8462H3.48693C3.31645 11.2456 3.23024 10.6243 3.23077 10ZM12.0285 6.92308H7.97154C8.38462 5.51231 9.07692 4.24077 10 3.23923C10.9231 4.24077 11.6154 5.51231 12.0285 6.92308ZM13.5546 8.15385H16.5131C16.8546 9.36092 16.8546 10.6391 16.5131 11.8462H13.5546C13.7382 10.6222 13.7382 9.37777 13.5546 8.15385ZM16.0285 6.92308H13.3031C12.989 5.68732 12.4616 4.51588 11.7446 3.46154C12.6607 3.70771 13.515 4.14286 14.2528 4.73902C14.9906 5.33518 15.5954 6.07915 16.0285 6.92308ZM8.25539 3.46154C7.53838 4.51588 7.01097 5.68732 6.69693 6.92308H3.97154C4.40456 6.07915 5.00941 5.33518 5.74719 4.73902C6.48497 4.14286 7.33935 3.70771 8.25539 3.46154ZM3.97154 13.0769H6.69693C7.01097 14.3127 7.53838 15.4841 8.25539 16.5385C7.33935 16.2923 6.48497 15.8571 5.74719 15.261C5.00941 14.6648 4.40456 13.9209 3.97154 13.0769ZM11.7446 16.5385C12.4616 15.4841 12.989 14.3127 13.3031 13.0769H16.0285C15.5954 13.9209 14.9906 14.6648 14.2528 15.261C13.515 15.8571 12.6607 16.2923 11.7446 16.5385Z" fill="white"/>-->
<!--						</svg>-->
<!--					</a>-->
<!--				</li>-->
				<li class="footer__item item">
					<a href="https://t.me/swap_coffee" class="item__link" target="_blank">
						<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M3.09992 9.02691C7.39488 7.05119 10.2589 5.74867 11.6919 5.11936C15.7834 3.32254 16.6335 3.01042 17.1877 3.00011C17.3095 2.99784 17.5821 3.02973 17.7586 3.18096C17.9076 3.30866 17.9486 3.48116 17.9683 3.60223C17.9879 3.7233 18.0123 3.9991 17.9929 4.2146C17.7712 6.6743 16.8118 12.6433 16.3237 15.3983C16.1172 16.564 15.7105 16.9548 15.3169 16.9931C14.4613 17.0762 13.8116 16.3961 12.9829 15.8225C11.6862 14.9251 10.9537 14.3664 9.69503 13.4907C8.24043 12.4786 9.18338 11.9223 10.0124 11.0132C10.2293 10.7753 13.999 7.15509 14.0719 6.82668C14.0811 6.7856 14.0895 6.6325 14.0034 6.55166C13.9172 6.47081 13.7901 6.49846 13.6983 6.52045C13.5683 6.55161 11.4968 7.99723 7.48389 10.8573C6.89591 11.2836 6.36333 11.4913 5.88616 11.4804C5.36012 11.4684 4.34822 11.1664 3.59598 10.9082C2.67333 10.5915 1.94002 10.4241 2.00388 9.88633C2.03714 9.60621 2.40248 9.31974 3.09992 9.02691Z" fill="white"/>
						</svg>
					</a>
				</li>
				<li class="footer__item item">
					<a href="https://x.com/swap_coffee_ton" class="item__link" target="_blank">
						<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M2.03902 3L8.21707 10.7228L2 17H3.4L8.84146 11.5026L13.239 17H18L11.4756 8.84397L17.261 3H15.8634L10.8512 8.06189L6.80244 3H2.03902ZM4.09756 3.96221H6.28537L15.9439 16.0355H13.7561L4.09756 3.96221Z" fill="white"/>
						</svg>
					</a>
				</li>
<!--				<li class="footer__item item">-->
<!--					<a href="" class="item__link" target="_blank">-->
<!--						<svg class="item__icon instagram-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
<!--							<path d="M10 18C8.28461 18 7.05018 17.9992 6.08776 17.9058C5.13636 17.8136 4.52776 17.6364 4.04206 17.3312C3.48694 16.9824 3.01754 16.513 2.66873 15.9579C2.36364 15.4722 2.1863 14.8636 2.09418 13.9122C2.00085 12.9498 2 11.7154 2 10C2 8.28461 2.00085 7.05018 2.09418 6.08776C2.1863 5.13636 2.36364 4.52776 2.66873 4.04206C3.01752 3.48693 3.48693 3.01752 4.04206 2.66873C4.52776 2.36364 5.13636 2.1863 6.08776 2.09418C7.05006 2.00085 8.28449 2 10 2C11.7154 2 12.9498 2.00085 13.9122 2.09418C14.8636 2.1863 15.4722 2.36364 15.9579 2.66873C16.513 3.01754 16.9824 3.48694 17.3312 4.04206C17.6364 4.52776 17.8136 5.13636 17.9058 6.08776C17.9992 7.05018 18 8.28461 18 10C18 11.7154 17.9992 12.9498 17.9058 13.9122C17.8136 14.8636 17.6364 15.4722 17.3312 15.9579C16.9824 16.513 16.513 16.9824 15.9579 17.3312C15.4722 17.6364 14.8636 17.8136 13.9122 17.9058C12.9498 17.9992 11.7154 18 10 18Z" stroke="white" stroke-width="1.38462"/>-->
<!--							<path d="M10.0001 13.4615C11.9119 13.4615 13.4617 11.9117 13.4617 9.99999C13.4617 8.08824 11.9119 6.53845 10.0001 6.53845C8.08836 6.53845 6.53857 8.08824 6.53857 9.99999C6.53857 11.9117 8.08836 13.4615 10.0001 13.4615Z" stroke="white" stroke-width="1.38462"/>-->
<!--							<path d="M14.4999 6.53847C15.0734 6.53847 15.5383 6.07354 15.5383 5.50001C15.5383 4.92648 15.0734 4.46155 14.4999 4.46155C13.9264 4.46155 13.4614 4.92648 13.4614 5.50001C13.4614 6.07354 13.9264 6.53847 14.4999 6.53847Z" fill="white"/>-->
<!--						</svg>-->
<!--					</a>-->
<!--				</li>-->
<!--				<li class="footer__item item">-->
<!--					<a href="" class="item__link" target="_blank">-->
<!--						<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
<!--							<path d="M9.99984 2C5.58169 2 2 5.59492 2 10.0294C2 13.7948 4.58299 16.9546 8.0674 17.8224V12.4832H6.41783V10.0294H8.0674V8.97208C8.0674 6.2392 9.29969 4.97248 11.9729 4.97248C12.4798 4.97248 13.3543 5.07236 13.7121 5.17193V7.39607C13.5233 7.37616 13.1953 7.3662 12.7879 7.3662C11.4763 7.3662 10.9694 7.86499 10.9694 9.16157V10.0294H13.5825L13.1335 12.4832H10.9694V18C14.9306 17.5198 18 14.1347 18 10.0294C17.9997 5.59492 14.418 2 9.99984 2Z" fill="white"/>-->
<!--						</svg>-->
<!--					</a>-->
<!--				</li>-->
<!--				<li class="footer__item item">-->
<!--					<a href="" class="item__link" target="_blank">-->
<!--						<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
<!--							<path d="M10.0201 2.00006C8.11737 1.99269 6.27459 2.6823 4.82325 3.94482C3.3719 5.20733 2.40727 6.95986 2.10291 8.8871C1.79854 10.8143 2.17443 12.7898 3.16293 14.458C4.15144 16.1262 5.68767 17.3777 7.49524 17.9873C7.89474 18.0529 8.02258 17.7987 8.02258 17.5774V16.1918C5.80938 16.6837 5.33797 15.1178 5.33797 15.1178C5.18876 14.6086 4.86529 14.1719 4.42712 13.888C3.70004 13.3797 4.48305 13.3961 4.48305 13.3961C4.73386 13.4294 4.97408 13.5204 5.18577 13.6624C5.39745 13.8044 5.57513 13.9936 5.70551 14.216C5.92745 14.6209 6.29622 14.9196 6.73162 15.047C7.16701 15.1744 7.63384 15.1203 8.03057 14.8964C8.06697 14.4808 8.24524 14.0917 8.53393 13.7978C6.82409 13.6175 4.9225 12.9124 4.9225 9.78872C4.89939 8.97005 5.18568 8.17393 5.72149 7.5669C5.47822 6.86136 5.5068 6.08711 5.80139 5.40247C5.80139 5.40247 6.47254 5.18111 7.99861 6.22233C9.30703 5.86141 10.6851 5.86141 11.9936 6.22233C13.5196 5.16471 14.1908 5.40247 14.1908 5.40247C14.4854 6.08711 14.5139 6.86136 14.2707 7.5669C14.8065 8.17393 15.0928 8.97005 15.0697 9.78872C15.0697 12.9206 13.2 13.6093 11.4183 13.8142C11.6102 14.0121 11.7586 14.2499 11.8537 14.5114C11.9488 14.773 11.9883 15.0524 11.9696 15.331V17.5774C11.9696 17.7987 12.0974 18.0611 12.5049 17.9873C14.3092 17.3788 15.8432 16.1307 16.8319 14.4667C17.8206 12.8028 18.1994 10.8319 17.9003 8.90739C17.6011 6.98288 16.6437 5.23064 15.1996 3.96484C13.7556 2.69905 11.9193 2.00249 10.0201 2.00006Z" fill="white"/>-->
<!--						</svg>-->
<!--					</a>-->
<!--				</li>-->
<!--				<li class="footer__item item">-->
<!--					<a href="" class="item__link" target="_blank">-->
<!--						<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
<!--							<path d="M18 9.55209C18 11.6716 17.6448 13.3909 17.2065 13.3909C16.7682 13.3909 16.4131 11.6721 16.4131 9.55209C16.4131 7.43208 16.7683 5.71326 17.2065 5.71326C17.6446 5.71326 18 7.43193 18 9.55209Z" fill="white"/>-->
<!--							<path d="M15.9754 9.55189C15.9754 11.9181 14.9653 13.8371 13.7192 13.8371C12.4731 13.8371 11.4629 11.9181 11.4629 9.55189C11.4629 7.18568 12.4729 5.26672 13.719 5.26672C14.9651 5.26672 15.9753 7.18506 15.9753 9.55189" fill="white"/>-->
<!--							<path d="M11.0249 9.5518C11.0249 12.0656 9.00462 14.1034 6.51255 14.1034C4.02048 14.1034 2 12.0651 2 9.5518C2 7.03845 4.02033 5 6.51255 5C9.00477 5 11.0249 7.03799 11.0249 9.5518Z" fill="white"/>-->
<!--						</svg>-->
<!--					</a>-->
<!--				</li>-->
<!--				<li class="footer__item item">-->
<!--					<a href="" class="item__link" target="_blank">-->
<!--						<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">-->
<!--							<path d="M12.0342 5.04977C12.2075 5.78485 12.8674 6.33244 13.6552 6.33244C14.5751 6.33244 15.3208 5.58643 15.3208 4.66622C15.3208 3.74601 14.5751 3 13.6552 3C12.851 3 12.1802 3.57025 12.024 4.32876C10.677 4.47327 9.62518 5.61611 9.62518 7.00112C9.62518 7.00424 9.62518 7.00658 9.62518 7.00971C8.16027 7.07142 6.82265 7.48856 5.76066 8.14708C5.36632 7.84165 4.87125 7.65964 4.33402 7.65964C3.0448 7.65964 2 8.70483 2 9.99453C2 10.9304 2.54973 11.7365 3.34388 12.1091C3.42118 14.8198 6.37365 17 10.0055 17C13.6373 17 16.5937 14.8174 16.6671 12.1045C17.455 11.7295 18 10.9257 18 9.99531C18 8.70561 16.9552 7.66042 15.666 7.66042C15.1311 7.66042 14.6384 7.84087 14.2448 8.14396C13.1735 7.48075 11.821 7.06361 10.3412 7.00815C10.3412 7.0058 10.3412 7.00424 10.3412 7.0019C10.3412 6.00982 11.0784 5.18648 12.0342 5.05133V5.04977ZM5.66696 11.4764C5.706 10.6296 6.26823 9.97969 6.92182 9.97969C7.5754 9.97969 8.07516 10.6663 8.03612 11.5131C7.99707 12.3599 7.50903 12.6677 6.85466 12.6677C6.20029 12.6677 5.62792 12.3232 5.66696 11.4764ZM13.0899 9.97969C13.7443 9.97969 14.3065 10.6296 14.3448 11.4764C14.3838 12.3232 13.8106 12.6677 13.1571 12.6677C12.5035 12.6677 12.0146 12.3607 11.9756 11.5131C11.9366 10.6663 12.4355 9.97969 13.0899 9.97969ZM12.3122 13.4356C12.4347 13.4481 12.5128 13.5754 12.4652 13.6894C12.0631 14.651 11.1135 15.3267 10.0055 15.3267C8.89741 15.3267 7.94866 14.651 7.54573 13.6894C7.4981 13.5754 7.57618 13.4481 7.69878 13.4356C8.41718 13.3629 9.19414 13.3231 10.0055 13.3231C10.8168 13.3231 11.593 13.3629 12.3122 13.4356Z" fill="white"/>-->
<!--						</svg>-->
<!--					</a>-->
<!--				</li>-->
				<li class="footer__item item">
					<a href="https://discord.gg/7wfjBH8pRu" class="item__link" target="_blank">
						<svg class="item__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
							<path d="M15.5447 4.99473C14.5249 4.53432 13.4313 4.1951 12.2879 4.00081C12.2671 3.99707 12.2463 4.00644 12.2356 4.02518C12.0949 4.27131 11.9392 4.59241 11.8301 4.84479C10.6003 4.66363 9.37679 4.66363 8.17221 4.84479C8.0631 4.5868 7.90166 4.27131 7.76038 4.02518C7.74966 4.00706 7.72886 3.99769 7.70803 4.00081C6.56527 4.19448 5.47171 4.53369 4.45129 4.99473C4.44246 4.99848 4.43488 5.00473 4.42986 5.01285C2.35559 8.06208 1.78737 11.0364 2.06612 13.9738C2.06738 13.9881 2.07558 14.0019 2.08693 14.0106C3.45547 14.9995 4.78114 15.5999 6.08219 15.9978C6.10301 16.0041 6.12507 15.9966 6.13832 15.9797C6.44608 15.5662 6.72043 15.1301 6.95565 14.6715C6.96953 14.6447 6.95628 14.6128 6.92791 14.6022C6.49275 14.4398 6.0784 14.2417 5.67982 14.0169C5.64829 13.9987 5.64577 13.9544 5.67477 13.9331C5.75865 13.8713 5.84255 13.8069 5.92264 13.742C5.93713 13.7301 5.95732 13.7276 5.97435 13.7351C8.59286 14.9114 11.4277 14.9114 14.0153 13.7351C14.0323 13.727 14.0525 13.7295 14.0677 13.7414C14.1478 13.8063 14.2316 13.8713 14.3161 13.9331C14.3451 13.9544 14.3433 13.9987 14.3117 14.0169C13.9131 14.2461 13.4988 14.4398 13.063 14.6016C13.0346 14.6122 13.022 14.6447 13.0359 14.6715C13.2762 15.1295 13.5505 15.5655 13.8526 15.9791C13.8652 15.9966 13.8879 16.0041 13.9087 15.9978C15.2161 15.5999 16.5417 14.9995 17.9103 14.0106C17.9223 14.0019 17.9298 13.9887 17.9311 13.9744C18.2647 10.5784 17.3723 7.62853 15.5655 5.01347C15.5611 5.00473 15.5535 4.99848 15.5447 4.99473ZM7.34668 12.1852C6.55833 12.1852 5.90876 11.473 5.90876 10.5984C5.90876 9.72381 6.54574 9.01165 7.34668 9.01165C8.15392 9.01165 8.79721 9.73006 8.78459 10.5984C8.78459 11.473 8.14761 12.1852 7.34668 12.1852ZM12.6632 12.1852C11.8748 12.1852 11.2253 11.473 11.2253 10.5984C11.2253 9.72381 11.8622 9.01165 12.6632 9.01165C13.4704 9.01165 14.1137 9.73006 14.1011 10.5984C14.1011 11.473 13.4704 12.1852 12.6632 12.1852Z" fill="white"/>
						</svg>
					</a>
				</li>
			</ul>
		</nav>
	</footer>
</template>

<script>
import computedMixins from "@/mixins/computedMixins";

export default {
	name: "AppFooter",
	mixins: [computedMixins],
	data() {
		return {}
	}
}
</script>

<style scoped>
.footer {
	width: 100%;
	padding: 20px;
}

.footer__list {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

.item__link {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 6px 12px;
	border-radius: 10px;
	background: var(--iface-white6);
}

.item__icon {
	width: 20px;
	height: 20px;
}

svg:not(.instagram-icon) path {
	fill: var(--main-text-color);
}

.instagram-icon path:last-child {
	fill: var(--main-text-color);
}

.instagram-icon path:not(:last-child) {
	stroke: var(--main-text-color);
}

@media screen and (max-width: 880px) {
	.dex_footer {
		padding-bottom: 85px;
	}

	.footer__list {
		flex-wrap: wrap;
	}
}
</style>