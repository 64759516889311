export default {
	state: () => ({
		dealConditions: null,
		pinnedTokens: [],
		tonTokens: [],
		userTokens: [],
		dexWallet: null,
		sendToken: null,
		receiveToken: null,
		sendAmount: 0,
		receiveAmount: 0,
		swapMode: 'default',
		slippage: 5,
		priceImpact: 10,
		maxPoolVolatility: -1,
		maxIntermediateTokens: 1,
		// cashback: true,
		isExpertMode: false,
		dexWalletVersion: null,
		maxSplitsValue: 4,

		stakingPool: null,

		// ton proof
		proofVerification: null,
		payloadId: null
	}),
	mutations: {
		SAVE_DEAL_CONDITIONS(state, item) {
			state.dealConditions = item
		},
		SAVE_STAKING_POOL(state, item) {
			state.stakingPool = item
		},
		SAVE_TON_TOKENS(state, item) {
			state.tonTokens = item
		},
		SAVE_PAYLOAD_ID(state, item) {
			state.payloadId = item
		},
		SAVE_PROOF_VERIFICATION(state, item) {
			state.proofVerification = item
		},
		SAVE_PINNED_TOKENS(state, item) {
			state.pinnedTokens = item
		},
		SAVE_USER_TOKENS(state, item) {
			state.userTokens = item
		},
		SAVE_DEX_WALLET(state, item) {
			state.dexWallet = item
		},
		SAVE_SEND_TOKEN(state, item) {
			state.sendToken = item
		},
		SAVE_RECEIVE_TOKEN(state, item) {
			state.receiveToken = item
		},
		SAVE_SEND_AMOUNT(state, item) {
			state.sendAmount = item
		},
		SAVE_RECEIVE_AMOUNT(state, item) {
			state.receiveAmount = item
		},
		SAVE_SWAP_MODE(state, item) {
			state.swapMode = item
		},
		SAVE_SLIPPAGE(state, item) {
			state.slippage = item
		},
		SAVE_PRICE_IMPACT(state, item) {
			state.priceImpact = item
		},
		SAVE_MAX_POOL_VOLATILITY(state, item) {
			state.maxPoolVolatility = item
		},
		SAVE_MAX_INTERMEDIATE_TOKENS(state, item) {
			state.maxIntermediateTokens = item
		},
		// SAVE_CASHBACK(state, item) {
		// 	state.cashback = item
		// },
		CLEAR_STORE(state) {
			state.dealConditions = null
			state.dexWallet = null
			state.sendToken = null
			state.receiveToken = null
			state.sendAmount = 0
			state.receiveAmount = 0
			state.swapMode = null
			state.slippage = 5
			state.priceImpact = 10
			state.maxPoolVolatility = -1
			state.maxIntermediateTokens = 1
			// state.cashback = true
			state.dexWalletVersion = null
			state.maxSplitsValue = 4
		},
		CLEAR_EXPERTS_SETTINGS(state) {
			state.priceImpact = 10
			state.maxPoolVolatility = -1
			state.maxIntermediateTokens = 1
		},
		SAVE_EXPERT_MODE_VALUE(state, item) {
			state.isExpertMode = item
		},
		SAVE_DEX_WALLET_VERSION(state, item) {
			state.dexWalletVersion = item
		},
		SAVE_MAX_SPLITS(state, item) {
			state.maxSplitsValue = item
		}
	},
	actions: {
		DEX_DEAL_CONDITIONS({commit}, item) {
			commit('SAVE_DEAL_CONDITIONS', item)
		},
		DEX_STAKING_POOL({commit}, item) {
			commit('SAVE_STAKING_POOL', item)
		},
		DEX_TON_TOKENS({commit}, item) {
			commit('SAVE_TON_TOKENS', item)
		},
		DEX_PROOF_VERIFICATION({commit}, item) {
			commit('SAVE_PROOF_VERIFICATION', item)
		},
		DEX_PAYLOAD_ID({commit}, item) {
			commit('SAVE_PAYLOAD_ID', item)
		},
		DEX_PINNED_TOKENS({commit}, item) {
			commit('SAVE_PINNED_TOKENS', item)
		},
		DEX_USER_TOKENS({commit}, item) {
			commit('SAVE_USER_TOKENS', item)
		},
		DEX_WALLET({commit}, item) {
			commit('SAVE_DEX_WALLET', item)
		},
		DEX_SEND_TOKEN({commit}, item) {
			commit('SAVE_SEND_TOKEN', item)
		},
		DEX_RECEIVE_TOKEN({commit}, item) {
			commit('SAVE_RECEIVE_TOKEN', item)
		},
		DEX_SEND_AMOUNT({commit}, item) {
			commit('SAVE_SEND_AMOUNT', item)
		},
		DEX_RECEIVE_AMOUNT({commit}, item) {
			commit('SAVE_RECEIVE_AMOUNT', item)
		},
		CHANGE_SWAP_MODE({commit}, item) {
			commit('SAVE_SWAP_MODE', item)
		},
		DEX_SLIPPAGE({commit}, item) {
			commit('SAVE_SLIPPAGE', item)
		},
		DEX_PRICE_IMPACT({commit}, item) {
			commit('SAVE_PRICE_IMPACT', item)
		},
		DEX_MAX_POOL_VOLATILITY({commit}, item) {
			commit('SAVE_MAX_POOL_VOLATILITY', item)
		},
		DEX_MAX_INTERMEDIATE_TOKENS({commit}, item) {
			commit('SAVE_MAX_INTERMEDIATE_TOKENS', item)
		},
		// DEX_CASHBACK({commit}, item) {
		// 	commit('SAVE_CASHBACK', item)
		// },
		CLEAR_DEX_STORE({commit}) {
			commit('CLEAR_STORE')
		},
		CLEAR_DEX_EXPERTS_SETTINGS({ commit }) {
			commit('CLEAR_EXPERTS_SETTINGS');
		},
		DEX_EXPERT_MODE({ commit }, item) {
			commit('SAVE_EXPERT_MODE_VALUE', item);
		},
		DEX_WALLET_VERSION({ commit }, item) {
			commit('SAVE_DEX_WALLET_VERSION', item)
		},
		DEX_MAX_SPLITS({ commit }, item) {
			commit('SAVE_MAX_SPLITS', item)
		}
	},
	getters: {
		GET_DEAL_CONDITIONS: state => {
			return state.dealConditions
		},
		GET_STAKING_POOL: state => {
			return state.stakingPool
		},
		GET_PROOF_VERIFICATION: state => {
			return state.proofVerification
		},
		GET_PAYLOAD_ID: state => {
			return state.payloadId
		},
		GET_TON_TOKENS: state => {
			return state.tonTokens
		},
		GET_PINNED_TOKENS: state => {
			return state.pinnedTokens
		},
		GET_USER_TOKENS: state => {
			return state.userTokens
		},
		GET_DEX_WALLET: state => {
			return state.dexWallet
		},
		GET_SEND_TOKEN: state => {
			return state.sendToken
		},
		GET_RECEIVE_TOKEN: state => {
			return state.receiveToken
		},
		GET_SEND_AMOUNT: state => {
			return state.sendAmount
		},
		GET_RECEIVE_AMOUNT: state => {
			return state.receiveAmount
		},
		GET_SWAP_MODE: state => {
			return state.swapMode
		},
		GET_SLIPPAGE: state => {
			return state.slippage
		},
		GET_PRICE_IMPACT: state => {
			return state.priceImpact
		},
		GET_MAX_POOL_VOLATILITY: state => {
			return state.maxPoolVolatility
		},
		GET_MAX_INTERMEDIATE_TOKENS: state => {
			return state.maxIntermediateTokens
		},
		// GET_CASHBACK: state => {
		// 	return state.cashback
		// },
		GET_EXPERT_MODE_VALUE: state => {
			return state.isExpertMode
		},
		GET_DEX_WALLET_VERSION: state => {
			return state.dexWalletVersion
		},
		GET_MAX_SPLITS: state => {
			return state.maxSplitsValue
		}
	}
}