import { createStore } from 'vuex'
import dex from "@/store/dex/dex";
import presale from "@/store/presale/presale";
import technicalWorks from "@/store/technical-works/technical-works";
import chart from "@/store/chart/chart";
import settings from "@/store/settings/settings";
import referral from "@/store/referral/referral";
import cashback from "@/store/cashback/cashback";
import claim from "@/store/claim/claim";

const store = createStore ({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        dex: dex,
        presale: presale,
        works: technicalWorks,
        chart: chart,
        settings: settings,
        cashback: cashback,
		referrals: referral,
        claim: claim
    }
})

export default store
