<template>
	<div class="claim">
		<AdvertisingBanner
			class="claim__banner"
		/>
		<div class="claim__container">
			<ClaimInfo
				:tonConnectUi="tonConnectUi"
			/>
			<div v-if="isWalletConnected">
				<ClaimTokens
					@claimAction="claimAction"
					@swapToTon="swapToTon"
					:cleanSelected="cleanSelected"
				/>
			</div>
		</div>
		<ClaimTransactionStatus
			v-if="showStatus"
			:claimStatus="claimStatus"
			:queryIds="queryIds"
			@closeStatus="closeStatus"
		/>
	</div>
</template>

<script>
import AdvertisingBanner from "@/components/banners/AdvertisingBanner.vue";
import ClaimInfo from "@/components/claim/ClaimInfo.vue";
import ClaimTokens from "@/components/claim/ClaimTokens.vue";
import {mapActions, mapGetters} from "vuex";
import computedMixins from "@/mixins/computedMixins";
import ClaimTransactionStatus from "@/components/claim/ClaimTransactionStatus.vue";
import tonConnectMixin from "@/mixins/tonConnectMixin";
import {toUserFriendlyAddress} from "@tonconnect/ui";

export default {
	name: "ClaimCenter",
	mixins: [computedMixins, tonConnectMixin],
	components: {
		ClaimTransactionStatus,
		ClaimTokens,
		ClaimInfo,
		AdvertisingBanner
	},
	props: {
		tonConnectUi: {
			type: Object,
			default() {
				return {}
			}
		},
	},
	data() {
		return {
			httpCount: 0,
			cleanSelected: false,
			showStatus: false,
			currentTypes: [],
			transactionsList: [],
			queryIds: [],
			claimStatus: null,
		}
	},
	computed: {
		...mapGetters([
			'GET_DEX_WALLET',
			'GET_PROOF_VERIFICATION',
			'GET_DEX_WALLET_VERSION'
		]),
		isWalletConnected() {
			return this.GET_DEX_WALLET !== null;
		}
	},
	methods: {
		...mapActions([
			'CLAIM_INFO',
			// 'CLAIM_TOKENS'
		]),
		async getClaimInfo() {
			if (this.GET_DEX_WALLET?.address && this.GET_PROOF_VERIFICATION) {
				try {
					let res = await this.dexApiV2.getClaimInfo(this.GET_DEX_WALLET.address, 'all', this.GET_PROOF_VERIFICATION)
					await this.CLAIM_INFO(res)
				} catch (err) {
					if (err.response.status === 403) {
						setTimeout(() => {
							this.disconnectWallet()
							this.httpCount = 0
						}, 1000)
					}
					console.error(err)
				}
			}
		},
		// async getClaimInfo() {
		// 	if (this.GET_DEX_WALLET?.address && this.GET_PROOF_VERIFICATION) {
		// 		try {
		// 			let res = await this.dexApiV2.getClaimInfo(this.GET_DEX_WALLET.address, 'all', this.GET_PROOF_VERIFICATION)
		// 			await this.CLAIM_INFO(res)
		// 		} catch (err) {
		// 			console.error(err)
		// 		}
		// 	}
		// },
		async claimAction(asset) {
			try {
				this.checkTokenTypes(asset.selectList)

				for (let type of this.currentTypes) {
					await this.claimTokensByType(type, asset.selectList)
				}
				// console.log(this.transactionsList)
				if (this.transactionsList.length > 0) {
					let transaction = await this.tonConnectUi.sendTransaction(this.getTransactionParams(this.transactionsList))
					await this.getClaimStatus()
					this.cleanSelected = true
					// this.loadClaimData()
					setTimeout(() => {
						this.cleanSelected = false
					}, 200)
				}
			} catch (err) {
				console.error(err)
			}
		},
		checkTokenTypes(selectTokens) {
			selectTokens.forEach((item) => {
				if (!this.currentTypes.includes(item?.claim_type)) {
					this.currentTypes.push(item?.claim_type)
				}
			})
		},
		async claimTokensByType(type, selectTokens) {
			try {
				let tokens = this.setTokensForRequest(type, selectTokens)
				let res = await this.dexApiV2.claimTokens(this.GET_DEX_WALLET?.address, type, tokens, this.GET_PROOF_VERIFICATION)

				for (let tx of res) {
					let address = tx.message.address
					let total = selectTokens
						.filter(token => token.claim_type === type && token.wallet_address === toUserFriendlyAddress(address))
						.reduce((sum, token) => sum + token.value, 0);
					if (isNaN(total)) {
						tx.totalAvailableUsd = 0
					} else {
						tx.totalAvailableUsd = total
					}
				}

				this.transactionsList = this.transactionsList.concat(res)
			} catch(err) {
				if (err.response.status === 403) {
					setTimeout(() => {
						this.disconnectWallet()
						this.httpCount = 0
					}, 1000)
				}
				console.error(err)
			}
		},
		setTokensForRequest(type, selectTokens) {
			let filteredTokens = []
			selectTokens.forEach((item) => {
				if (type === item?.claim_type) {
					let asset = {
						"amount": Number(item?.amount),
						"token_address": item?.friendly_address,
						"wallet_address": item?.wallet_address
					}
					filteredTokens.push(asset)
				}
			})
			return filteredTokens
		},
		getTransactionParams(trInfo) {
			let sortedTransactions = trInfo.sort((a, b) => b?.totalAvailableUsd - a?.totalAvailableUsd)
			// console.log('Все транзакции от бека', sortedTransactions)
			let messages = []
			this.queryIds = []

			sortedTransactions.forEach((item, index) => {
				if (this.GET_DEX_WALLET_VERSION < 5 && index < 4 || this.GET_DEX_WALLET_VERSION >= 5) {
					if (!this.queryIds.includes(item?.query_id)) {
						this.queryIds.push(item?.query_id)
					}
					messages.push({
						address: item?.message?.address,
						amount: item?.message?.value,
						payload: item?.message?.payload_cell
					})
				}
			})
			// console.log('Итоговые сообщения в send transaction', messages)
			return {
				validUntil: Math.floor(Date.now() / 1000) + 300,
				messages: messages,
			}
		},
		async getClaimStatus() {
			try {
				this.claimStatus = await this.dexApiV2.getClaimStatus(this.queryIds)
				this.showStatus = true
			} catch(err) {
				console.error(err)
			}
		},
		closeStatus() {
			this.loadClaimData()
			this.showStatus = false
		},
		swapToTon(selectTokens) {
			console.log('swap to ton')
		},
		loadClaimData() {
			this.httpCount++
			this.getClaimInfo()
			// this.getClaimStats()
			// this.getClaimTokens()
		}
	},
	mounted() {
		setTimeout(() => {
			if (this.GET_DEX_WALLET !== null && this.httpCount === 0) {
				this.loadClaimData()
			}
		}, 500);
	},
	unmounted() {
		this.CLAIM_INFO(null)
	},
	watch: {
		GET_DEX_WALLET: {
			handler() {
				if (this.GET_DEX_WALLET !== null && this.httpCount === 0) {
					this.loadClaimData()
				}
			}
		},
		showStatus: {
			handler() {
				if (this.showStatus) {
					document.documentElement.style.overflow = 'hidden'
					return
				}
				document.documentElement.style.overflow = 'auto'
			}
		}
	},
}
</script>

<style scoped>
.claim {
	display: flex;
	flex-direction: column;
	max-width: 980px;
	width: 980px;
	margin: 14px auto 40px auto;
	color: #fff;
}

.claim__container {
	padding: 0 20px;
}

.claim__banner {
	padding: 0 20px;
}

@media screen and (max-width: 1180px) {
	.claim {
		margin-top: 0;
		width: 100%;
		max-width: 100%;
	}

	.claim__container {
		padding: 0 14px;
	}

	.claim__banner {
		padding: 14px;
	}
}

@media screen and (max-width: 880px) {
	.claim__container {
		padding: 0 10px;
	}

	.claim__banner {
		padding: 12px 10px;
	}
}

@media screen and (max-width: 460px) {
	.claim {
		width: 100%;
	}
}
</style>