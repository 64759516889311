export default {
	state: () => ({
		theme: 'dark',
		settings: null
	}),
	mutations: {
		SET_THEME(state, value) {
			state.theme = value;
		},
		SET_USER_SETTINGS(state, value) {
			state.settings = value;
		},
	},
	actions: {
		TOGGLE_THEME({commit}, item) {
			commit('SET_THEME', item)
		},
		SAVE_USER_SETTINGS({commit}, item) {
			commit('SET_USER_SETTINGS', item)
		},
	},
	getters: {
		GET_THEME: state => {
			return state.theme;
		},
		GET_USER_SETTINGS: state => {
			return state.settings;
		}
	}

}