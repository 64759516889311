<template>
	<header class="header"
			:class="{active_header: showMenu === true}"
	>
		<div class="header__flex-group">
			<div class="flex_left">
				<button class="header__link"
				   		@click="sendByLink('MAIN')"
				>
					<img v-if="GET_THEME === 'light'" src="@/assets/header/logo-dark.svg" alt="swap logo for light theme" class="header__logo">
					<img v-else src="~@/assets/header/swap-logo.svg" alt="swap logo for dark theme" class="header__logo"/>
					<img src="~@/assets/header/swap-logo-mob.svg" alt="swap logo for mobile devices" class="header__logo_mob"/>
				</button>
			</div>
			<div class="header__button-block">
				<div class="header__dex-wallet"
					 v-show="GET_DEX_WALLET !== null"
					 @click="handleShowSettingsPopup"
				>
					<img :src="GET_DEX_WALLET?.imgUrl" alt="wallet logo" class="header__dex-image">
					<p class="header__dex-address">{{ getTrimAddress }}</p>
					<svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
						<path d="M11.3538 8.35375L6.35378 13.3538C6.30733 13.4002 6.25218 13.4371 6.19148 13.4622C6.13079 13.4873 6.06573 13.5003 6.00003 13.5003C5.93434 13.5003 5.86928 13.4873 5.80859 13.4622C5.74789 13.4371 5.69274 13.4002 5.64628 13.3538C5.59983 13.3073 5.56298 13.2521 5.53784 13.1915C5.5127 13.1308 5.49976 13.0657 5.49976 13C5.49976 12.9343 5.5127 12.8693 5.53784 12.8086C5.56298 12.7479 5.59983 12.6927 5.64628 12.6463L10.2932 8L5.64628 3.35375C5.55246 3.25993 5.49976 3.13269 5.49976 3C5.49976 2.86732 5.55246 2.74007 5.64628 2.64625C5.7401 2.55243 5.86735 2.49973 6.00003 2.49973C6.13272 2.49973 6.25996 2.55243 6.35378 2.64625L11.3538 7.64625C11.4003 7.69269 11.4372 7.74783 11.4623 7.80853C11.4875 7.86923 11.5004 7.9343 11.5004 8C11.5004 8.06571 11.4875 8.13077 11.4623 8.19147C11.4372 8.25217 11.4003 8.30732 11.3538 8.35375Z" fill="white"/>
					</svg>
				</div>
				<button class="header__dex-button"
						v-show="GET_DEX_WALLET === null"
						@click="showTonconnect"
				>
					{{ $t('dexButton.connectWallet') }}
				</button>
				<button class="header__burger"
						:class="{active_burger: showMenu === true}"
						@click="showMenu = !showMenu"
				>
					<div class="top-line"></div>
					<div class="bottom-line"></div>
				</button>
			</div>
		</div>
		<MobileNav
			v-show="showMenu"
		/>
	</header>
	<DexWalletSettingsPopup
		v-if="showWalletSettingsPopup"
		:walletImage="tonConnectUi?.walletInfo?.imageUrl"
		@closeSettings="handleShowSettingsPopup"
		@logout="disconnectWallet"
	/>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import computedMixins from "@/mixins/computedMixins";
import MobileNav from "@/components/navigation/MobileNav.vue";
import methodsMixins from "@/mixins/methodsMixins";
import DexWalletSettingsPopup from "@/components/dex/DexWalletSettingsPopup.vue";

export default {
	name: 'Header',
	mixins: [computedMixins, methodsMixins],
	components: {
		DexWalletSettingsPopup,
		MobileNav,
	},
	props: {
		tonConnectUi: {
			type: Object,
			default() {
				return {}
			}
		},
	},
	data() {
		return {
			showMenu: false,
			showWalletSettingsPopup: false,
			walletImg: null,
		}
	},
	computed: {
		...mapGetters([
			'GET_DEX_WALLET',
			'GET_TECHNICAL_WORKS_STATUS',
			'GET_THEME'
		]),
		getStatus() {
			return this.GET_TECHNICAL_WORKS_STATUS;
		},
		getImageUrl() {
			console.log(this.tonConnectUi)
			return this.tonConnectUi?.walletInfo?.imageUrl
			// let storage = JSON.parse(localStorage.getItem('ton-connect-ui_last-selected-wallet-info'))
			// if (storage) {
			// 	return storage.imageUrl
			// } else {
			// 	return ''
			// }
			// console.log('WALLET_INFO', this.tonConnectUi?.walletInfo)
			// if (this.tonConnectUi?.walletInfo?.imageUrl) {
			// 	return this.tonConnectUi?.walletInfo?.imageUrl
			// } else {
			// 	let storage = JSON.parse(localStorage.getItem('ton-connect-ui_last-selected-wallet-info'))
			// 	if (storage) {
			// 		return storage.imageUrl
			// 	} else {
			// 		return ''
			// 	}
			// }
		}
	},
	methods: {
		...mapActions([
			'SAVE_USER_SETTINGS'
		]),
		async disconnectWallet() {
			try {
				await this.tonConnectUi.disconnect()
				this.showWalletSettingsPopup = false;
				this.SAVE_USER_SETTINGS(null)
			} catch (err) {
				console.error(err)
			}
		},
		linkWallet() {
			this.$emit('linkWallet')
		},
		sendByLink(value) {
			this.$router.push({name: 'Dex'})
		},
		handleShowSettingsPopup() {
			this.showWalletSettingsPopup = !this.showWalletSettingsPopup;
		},
		async showTonconnect() {
			try {
				await this.tonConnectUi.openModal()
				console.log('modal is open')
			} catch(err) {
				console.error(err)
			}
		}
	},
	watch: {
		showMenu: {
			handler: function () {
				if (this.showMenu && document.body.clientWidth <= 660) {
					document.documentElement.style.overflow = 'hidden'
					return
				}
				document.documentElement.style.overflow = 'auto'
			},
			deep: true
		},
		getRouteName: {
			handler() {
				if (window.innerWidth <= 1200) {
					this.showMenu = false
				}
			}
		}
	},
}
</script>

<style scoped>

.header {
	position: fixed;
	z-index: 998;
	left: 0;
	right: 0;
	top: 0;
	padding: 14px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: var(--main-bg-color);
	backdrop-filter: blur(7.5px);
	transform: translate3d(0, 0, 10px);
	height: 68px;
}

.header__flex-group {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.flex_left {
	display: flex;
	align-items: center;
}

.header__link {
	display: block;
	width: 172px;
	height: 40px;
	padding: 0 0;
	cursor: pointer;
	border: none;
	background: transparent;
}

.header__logo {
	width: 172px;
	height: 40px;
}

.header__logo_mob {
	display: none;
}

.header__dex-wallet {
	display: flex;
	align-items: center;
	padding: 8px;
	border-radius: 16px;
	background: var(--iface-white6);
	gap: 8px;
	height: 40px;
	cursor: pointer;
}

.header__dex-wallet:hover .header__dex-address {
	opacity: 1;
}

.theme-light .header__dex-wallet {
	background: transparent;
	border: 1px solid var(--iface-white10);
}

.header__dex-image {
	border-radius: 100px;
	width: 24px;
	height: 24px;
}

.header__dex-block {
	transition: .2s;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	padding: 9px 8px 7px 8px;
	border-radius: 20px;
	background: var(--iface-white8);
	cursor: pointer;
}

.theme-light .header__dex-block {
	background: var(--iface-white4);
}

.header__dex-block:hover {
	background: var(--iface-white10);
}

.header__dex-block::before {
	content: '';
	display: inline-block;
	width: 16px;
	height: 16px;
	margin-left: 6px;
	position: relative;
	background: url("@/assets/dex/arrow-down.svg") no-repeat;
	transform: rotate(270deg);
}

.theme-light .header__dex-block::before {
	mix-blend-mode: difference;
}

.header__dex-address {
	transition: .2s;
	max-width: 85px;
	overflow: hidden;
	white-space: nowrap;
	font-size: 14px;
	line-height: 16px;
	opacity: 0.8;
}

.header__dex-disconnect-btn {
	transition: .2s;
	width: 32px;
	height: 32px;
	border: none;
	border-radius: 100px;
	background: rgba(255, 255, 255, 0.2) url('~@/assets/interface/remove-icon.svg') center no-repeat;
}

.header__dex-disconnect-btn:hover {
	background: rgba(255, 255, 255, 0.3) url('~@/assets/interface/remove-icon.svg') center no-repeat;
}

.header__dex-button {
	transition: .2s;
	width: 180px;
	height: 40px;
	border-radius: 100px;
	border: none;
	outline: none;
	background: var(--iface-accent-color);;
	font-size: 14px;
	color: #fff;
}

.header__dex-button:hover {
	background: var(--iface-accent-hover);
}

.header__button-block {
	width: 180px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.header__btn:hover .header__btn-icon {
	transform: translateX(4px);
}

.header__burger {
	display: none;
}

.active_address .arrow-icon {
	transform: rotateX(180deg);
}

.arrow-icon path {
	fill: var(--main-text-color);
}

@media screen and (max-width: 1180px) {

	.header {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		border-bottom: 1px solid var(--iface-white8);
	}

	.header__link {
		width: auto;
	}

	.header__button-block {
		width: auto;
	}

	.active_header {
		height: 100vh;
		backdrop-filter: blur(25px);
	}

	.header__burger {
		position: relative;
		margin-left: 8px;
		width: 40px;
		height: 40px;
		display: flex;
		border: none;
		outline: none;
		border-radius: 100px;
		background: var(--main-bg-color);
	}

	.top-line,
	.bottom-line {
		transition: .15s linear;
		position: absolute;
		min-width: 20px;
		height: 2px;
		background-color: var(--main-text-color);
	}

	.top-line {
		top: 17px;
		left: 12px;
	}

	.bottom-line {
		top: 25px;
		left: 12px;
	}

	.active_burger .top-line {
		transform-origin: 6px 0;
		transform: rotateZ(45deg);
		width: 25px;
	}

	.active_burger .bottom-line {
		transform-origin: 6px 0;
		transform: rotateZ(-45deg) translate(-1px, 0);
		width: 25px;
	}
}

@media screen and (max-width: 880px) {
	.header {
		height: 64px;
		padding: 12px 10px;
	}

	.active_header {
		height: 100vh;
		backdrop-filter: blur(25px);
	}

	.header__btn {
		padding: 9.5px 9px 8.5px 15px;
	}
}

@media screen and (max-width: 640px) {
	.header {
		//padding: 10px;
	}

	.header__link {
		width: 44px;
	}

	.header__logo {
		display: none;
	}

	.header__logo_mob {
		display: block;
		width: 40px;
		height: 40px;
	}
}

</style>